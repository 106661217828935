.organisation-groups-dropdown_outside-wrapper {
  align-items: center;
  border: 2px solid #a0a7b7;
  border-radius: 4px;
  color: #134176;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 23px;
  margin-right: 1rem;
  padding: 11px 12px;
  width: 250px;
}

.organisation-groups-dropdown_popover-content-wrapper {
  display: flex;
  padding: 10px 40px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-title {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-checkboxes .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-checkboxes .ant-checkbox-group .ant-checkbox-wrapper {
  border-bottom: 1px solid #D8D8D8;
  display: flex;
  margin-bottom: 18px;
  margin-left: 0 !important;
  padding-bottom: 18px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-checkboxes .ant-checkbox-group .ant-checkbox-wrapper > span:not(.ant-checkbox) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-checkboxes .ant-checkbox-group .ant-checkbox-wrapper span {
  font-weight: 500;
  line-height: 18px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-checkboxes .ant-checkbox-group .ant-checkbox-wrapper span svg {
  color: #1E3968;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .ant-radio-wrapper {
  display: flex;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #D8D8D8;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .ant-radio-wrapper > span:not(.ant-radio) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .ant-radio-wrapper span {
  font-weight: 500;
  line-height: 18px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .ant-radio-wrapper span svg {
  color: #1E3968;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .anticon-edit {
  margin-left: 36px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-content .organisation-groups-dropdown_popover-radios .ant-radio-group .anticon-delete {
  margin-left: 16px;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-button {
  background: #21416F;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #FFF;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 25px auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.organisation-groups-dropdown_popover-content-wrapper .organisation-groups-dropdown_popover-button:hover {
  opacity: 0.5;
}