.individual-organisation-dropdown_outside-wrapper {
  align-items: center;
  border: 2px solid #a0a7b7;
  border-radius: 4px;
  color: #134176;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 23px;
  padding: 11px 12px;
  width: 200px;
}

.individual-organisation-dropdown_popover-content {
  width: 100%;

  .individual-organisation-dropdown_popover-title {
    color: #000000;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 4px;
  }
  
  .individual-organisation-dropdown_popover-subtitle { 
    color: #000000;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin-bottom: 14px;
  }
  
  .individual-organisation-dropdown_popover-checkboxes {
    max-height: 200px;
    overflow: auto;
  
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  
    .ant-checkbox-wrapper {
      align-items: center;
      color: #000000;
      display: flex;
      font-size: 16px;
      line-height: 30px;
      max-width: 200px;
  
      span {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #21416F;
        border-color: #21416F;
      }
    }
  }
  
  .individual-organisation-dropdown_popover-action-button {
    background: #21416F;
    border: 1px solid #21416F;
    border-radius: 24px;
    color: #FFF;
    display: flex;
    font-size: 14px;
    line-height: 18px;
    margin: 25px auto 0;
    outline: none;
    padding: 10px 30px;
    transition: .3s all;
  
    &:hover {
      opacity: .5;
    }
  }
}