.comparison-group-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  min-height: 600px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 700px 1fr;
  }

  .column {
    padding: 5px;
    margin: 5px;
    height: 100%;
  }

  .forms {
    margin-left: 0;
    padding-left: 0;
    border-right: 1px solid #afafaf;

    .items-list {
      max-width: 95%;
    }
  }

  .organizations {
    margin-left: 0;
    padding-left: 0;
    border-right: 1px solid #afafaf;

    .items-list {
      max-width: 95%;
    }
  }

  .no-records {
    margin-top: 20px;
    justify-content: flex-start;
  }

  .input-search {
    display: block;
    width: 95%;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 2px solid #eee;
    outline: none;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    &::placeholder {
      font-size: 12px;
      font-weight: bold;
      color: #a09f9f;
    }

    &:focus {
      border: 2px solid #0b72b5;
    }
  }

  .indicators {
    border-right: 1px solid #afafaf;

    .items-list {
      max-width: 95%;
    }
  }

  .values {
    .items-list {
      max-width: 95%;
    }
  }

  .items-list {
    display: flex;
    max-width: 90%;
    flex-direction: column;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      //background-color: #efefef;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 15px 5px 10px;
      max-width: 100%;

      &:hover {
        color: #0b72b5;
      }
    }

    .item-active {
      color: #0b72b5;
      font-weight: bold;
    }
  }
}

.comparison-group-footer {
  margin-top: 15px;
  width: calc(100% - 20px);
  text-align: right;
}

.comparison-group-rules {
  display: block;

  .rules-item {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 3fr 1fr;
    margin-bottom: 5px;
  }
}

.close-modal {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #0b72b5;
    transform: rotate(45deg);
    position: absolute;
    bottom: 10px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #0b72b5;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 10px;
  }
}
