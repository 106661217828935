//
// topbar.scss
//

// Logo
.logo {
    display: block;
    line-height: $topbar-height;
    span.logo-lg {
        display: block;
    }
    span.logo-sm {
        display: none;
    }
    .logo-lg-text-dark {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
    .logo-lg-text-light {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
}

.logo-box {
    height: $topbar-height;
    width: $leftbar-width;
    float: left;
}

.navbar-custom {
    padding: 0 10px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    height: $topbar-height;
    border-bottom: 0.5px solid #d8d8d8;

    .logo-box .logo-lg img {
        width: 215px;
        height: $topbar-height;
        background: $bg-topbar-beige;
        object-fit: contain;
        object-position: center;
    }

    .topnav-menu {
        > li {
            float: left;
        }
        .nav-link {
            padding: 0 15px;
            color: #21416f;
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .anticon.anticon-down {
                    margin-top: 5px;
                    margin-left: 5px;
                }
            }
            .btn {
                background: white;
                color: #21416f;
                font-size: 14px;

                .fe-search {
                    margin-right: 10px;
                }
            }
        }
    }

    .topnav-menu-left {
        font-size: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        height: 100%;
        font-weight: 600;
        color: #000000;
        padding-left: 24px;
    }

    .dropdown.show {
        .nav-link {
            background-color: rgba($white, 0.05);
        }
    }

    .topnav-menu-left_link {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 30px;

        a {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 1.25;
            color: #134176;
        }
    }
}

/* Notification */
.noti-scroll {
    max-height: 230px;
}

.notification-list {
    margin-left: 0;

    .noti-title {
        background-color: $white;
        padding: 15px 20px;
    }

    .noti-icon {
        font-size: 21px;
        vertical-align: middle;
    }

    .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 10px;
    }

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }

        .notify-details {
            margin-bottom: 5px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $gray-800;

            b {
                font-weight: 500;
            }
            small {
                display: block;
            }
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }
    .profile-dropdown {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.profile-dropdown {
    width: 200px;
    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}

.nav-user {
    padding: 0 12px !important;
    img {
        height: 32px;
        width: 32px;
    }
}

// Topbar light
.navbar-custom-light {
    background-color: $bg-topbar-light !important;

    .topnav-menu {
        .nav-link {
            color: $gray-700;
        }
    }
    .dropdown.show {
        .nav-link {
            background-color: rgba($dark, 0.03);
        }
    }

    .button-menu-mobile {
        color: $dark;
    }
}
