.change__password {
  margin-top: 25px;
  padding: 25px 18px;
  border: 1px solid #D8D8D8;
  border-radius: 2px;

  .title {
    margin-bottom: 14px;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .ant-form {

    .ant-row {
      margin-bottom: 15px!important;

      &:last-child {
        margin-bottom: 0!important;
      }
    }

    .ant-col {
      padding-bottom: 0px;
    }

    label {
      flex-direction: row-reverse;
      font-size: 14px;
      line-height: 23px;
      font-weight: normal;
      color: #333333;

      &:before {
        margin-right: 0;
        margin-left: 4px;
      }
    }



    .ant-input-password {
      max-width: 385px;
      width: 100%;
      border: 2px solid #A0A7B7;
      border-radius: 3px;
      outline: none;
      color: #333333;
    }

    input {
      &::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #333333;
      }

      &:focus {
        border-color: #A0A7B7;
      }
    }


    .generate__button {
      margin-top: 5px;
      display: block;
      width: 120px;
      cursor: pointer;
    }

    .ant-checkbox-wrapper {
      margin-left: 0;
      font-size: 14px;
      line-height: 23px;
      color: #333333;

      .ant-checkbox-checked .ant-checkbox-inner {
        background: #1E3968;
        border: 2px solid #1E3968;
      }

      .ant-checkbox-inner {
        border: 2px solid #A0A7B7;
      }
    }
  }

  .action__button {
    padding: 8px 26px;
    margin-top: 12px;
    height: auto;
    background: #1E3968;
    border: 1px solid #134176;
    outline: none;
    border-radius: 18px;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    transition: .3s all;

    &:hover, &:active, &:focus {
      opacity: .5;
      background: #1E3968;
      border: 1px solid #134176;
      color: #fff;
    }
  }
}