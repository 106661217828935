.popover__organization {
  padding: 10px 40px 22px 12px;

  .organization__action {
    margin-top: 25px;
  }

  .radio__groups {
    display: flex;
  }

  .radio__group {

    &:first-child {
      margin-right: 72px;
    }

    .radio__title {
      margin-bottom: 14px;
      font-size: 18px;
      line-height: 23px;
    }

    .ant-radio-group {
      max-height: 200px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .ant-radio-wrapper {
        margin-bottom: 5px;
      }

      .ant-radio-checked .ant-radio-inner {
        background: #1E3968;
        border-color: #1E3968;

        &::after {
          content: '';
          background-color: #fff;
        }
      }
    }
  }
}

.popover__group {
  padding: 10px 40px 22px 12px;

  .group__block {
    display: flex;
  }

  .group__radio--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #D8D8D8;
  }

  .group__item {

    &:first-child {
      border-right: 1px solid #D8D8D8;
      padding-right: 50px;
    }

    &:last-child {
      padding-left: 50px;
    }

    .group__title {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 23px;
    }

    .group__icons {
      display: flex;
      align-items: center;
    }

    .ant-radio-group {
      max-height: 300px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .ant-radio-wrapper {
        display: flex;

        > span:not(.ant-radio) {
          display: block;
          width: 100%;
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          font-weight: 500;
          line-height: 18px;

          svg {
            color: #1E3968;
          }
        }
      }

      .anticon-edit {
        width: 16px;
        height: 16px;
        transition: .4s all;

        svg {
          width: 16px;
          height: 16px;
          fill: #1E3968;
        }

        &:hover {
          opacity: .4;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        background: #1E3968;
        border-color: #1E3968;

        &::after {
          content: '';
          background-color: #fff;
        }
      }
    }

    .delete-group {
      position: relative;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      transition: .4s all;

      &:hover {
        opacity: .4;
      }

      &:after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border: 1px solid #C82922;
        background: #C82922;
        top: 6px;
      }

      &:before {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        transform: rotate(-45deg);
        border: 1px solid #C82922;
        background: #C82922;
        top: 6px;
      }
    }


  }
}

.group__action {
  display: flex;
  margin: 50px auto 0;
  padding: 10px 30px;
  border: 1px solid #21416F;
  background: transparent;
  outline: none;
  border-radius: 24px;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  transition: .3s all;

  &:hover {
    background: #21416F;
    color: #fff;
  }
}

.apply__button {
  background: #21416F;
  color: #fff;

  &:hover {
    opacity: .5;
  }
}

.popover__content {

  .popover__title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    span {
      font-size: 14px;
      line-height: 37px;
    }
  }

  .ant-checkbox-group-item {
    margin-right: 0;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #134176;
    border: 1px solid #134176;
    border-radius: 3px;
  }
}

.popover__individual--group {

  .group__block {
    max-width: 200px;
    width: 100%;
  }

  .group__title {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .group__subtitle {
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 18px;
    font-style: italic;
    color: #000000;
  }

  .group__checkboxes {
    max-height: 200px;
    overflow: auto;

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
      max-width: 200px;

      span {
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #21416F;
        border-color: #21416F;
      }
    }
  }

  .action__button {
    display: flex;
    margin: 25px auto 0;
    padding: 10px 30px;
    border: 1px solid #21416F;
    outline: none;
    border-radius: 24px;
    font-size: 14px;
    line-height: 18px;
    transition: .3s all;
    background: #21416F;
    color: #fff;

    &:hover {
      opacity: .5;
    }
  }
}