.footer__content {
  margin-left: 215px;
  display: block;
  position: relative;
  padding: 10px 15px;
  color: #21416f;
  background-color: #f3f3f3;

  p {
    margin: 0;
  }
}