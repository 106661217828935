.change__password {
  margin-top: 25px;
  padding: 25px 18px;
  border: 1px solid #D8D8D8;
  border-radius: 2px;
}
.change__password .title {
  margin-bottom: 14px;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}
.change__password .ant-form .ant-row {
  margin-bottom: 15px !important;
}
.change__password .ant-form .ant-row:last-child {
  margin-bottom: 0 !important;
}
.change__password .ant-form .ant-col {
  padding-bottom: 0px;
}
.change__password .ant-form label {
  flex-direction: row-reverse;
  font-size: 14px;
  line-height: 23px;
  font-weight: normal;
  color: #333333;
}
.change__password .ant-form label:before {
  margin-right: 0;
  margin-left: 4px;
}
.change__password .ant-form .ant-input-password {
  max-width: 385px;
  width: 100%;
  border: 2px solid #A0A7B7;
  border-radius: 3px;
  outline: none;
  color: #333333;
}
.change__password .ant-form input::placeholder {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.change__password .ant-form input:focus {
  border-color: #A0A7B7;
}
.change__password .ant-form .generate__button {
  margin-top: 5px;
  display: block;
  width: 120px;
  cursor: pointer;
}
.change__password .ant-form .ant-checkbox-wrapper {
  margin-left: 0;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
}
.change__password .ant-form .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background: #1E3968;
  border: 2px solid #1E3968;
}
.change__password .ant-form .ant-checkbox-wrapper .ant-checkbox-inner {
  border: 2px solid #A0A7B7;
}
.change__password .action__button {
  padding: 8px 26px;
  margin-top: 12px;
  height: auto;
  background: #1E3968;
  border: 1px solid #134176;
  outline: none;
  border-radius: 18px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition: 0.3s all;
}
.change__password .action__button:hover, .change__password .action__button:active, .change__password .action__button:focus {
  opacity: 0.5;
  background: #1E3968;
  border: 1px solid #134176;
  color: #fff;
}