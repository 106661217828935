.field__item > div {
  height: 100%;
  padding-bottom: 10px;
}

.field__item > div > div {
  height: 100%;
}

.field__item > div > div > table {
  height: 100%;
}

tr, td {
  padding-top: 10px;
  padding-bottom: 10px;
}

// ::-webkit-scrollbar {
//   width: 0px;
//   height: 0px;
// }

iframe {
  margin-top: -10px;
}
// tbody {
//   padding-bottom: 10px !important;
//   tr {
//     td {
//       background: none !important;
//     }
//   }
// }

article.node--type-basic-page-report-blocks {
  & > header {
    display: none;
  }

  & > h2:is(:first-child) {
    display: none;
  }

  & > footer.node__meta {
    display: none;
  }
}
