.questionnaireCheckboxes {
  display: flex !important;
  flex-direction: column !important;
}
.questionnaireCheckboxes label {
  margin-bottom: 15px;
}
.questionnaireCheckboxes label .ant-checkbox .ant-checkbox-inner {
  border: solid 2px #a0a7b7 !important;
  width: 18px;
  height: 18px;
}
.questionnaireCheckboxes label .ant-checkbox .ant-checkbox-inner:hover {
  border: solid 2px #525566;
}
.questionnaireCheckboxes .ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner {
  border: solid 1px #134176 !important;
  background-color: #134176;
}