.user__item {
  padding: 20px 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  cursor: pointer;
}
.user__item p {
  margin: 0;
}
.user__item .user__info {
  width: 25%;
  display: flex;
  align-items: center;
}
.user__item .user__info .user__img {
  min-width: 38px;
  max-width: 38px;
  height: 38px;
}
.user__item .user__info .user__img img {
  width: 38px;
  height: 38px;
  object-fit: cover;
  border-radius: 50%;
}
.user__item .user__info .user__img span {
  padding-top: 9px;
  width: 100%;
  display: block;
  height: 100%;
  background: #EBEDF1;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 18px;
  color: #7A8295;
}
.user__item .user__info .user__name {
  max-width: 160px;
  margin-left: 10px;
  font-size: 18px;
  line-height: 23px;
  color: #21416F;
}
.user__item .user__email {
  width: 35%;
  display: flex;
}
.user__item .user__email p {
  margin-left: 5px;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  color: #333333;
}
.user__item .user__role {
  width: 15%;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  color: #333333;
}
.user__item .user__action {
  cursor: pointer;
  transition: 0.3s all;
}
.user__item .user__action svg {
  color: #21416F;
}
.user__item .user__action:hover {
  transform: scale(1.1);
}