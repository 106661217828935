.user__edit {
  padding: 48px 100px 48px 0;

  .user__info {
    padding-bottom: 22px;
    display: flex;
  }

  .feedback {
    padding: 7px 25px;
    border: 1px solid #134176;
    border-bottom: none;
    position: fixed;
    font-size: 16px;
    line-height: 20px;
    color: #134176;
    top: 50%;
    left: 100%;
    transform: translateX(-64%) rotate(-90deg);
    cursor: pointer;
  }

  .img__block {
    position: relative;
    width: 64px;
    height: 64px;
    cursor: pointer;

    .avatar {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 50%;
      background: #7A8295;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
      }
    }

    input[type="file"] {
      width: 64px;
      height: 64px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }

    svg {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .text__block {
    margin-left: 14px;

    .name {
      margin: 0;
      font-size: 48px;
      line-height: 62px;
      color: #000000;
    }

    .last__visit {
      margin: 0;
      font-size: 12px;
      line-height: 23px;
      color: #333333;
      font-style: italic;
    }
  }
}