.organization-tab__group {
  display: flex;
  justify-content: space-between;
}
.organization-tab__group .organization-tab__item {
  width: 25%;
  padding: 0 15px;
  border-right: 1px solid #EFEFEF;
}
.organization-tab__group .organization-tab__item:last-child {
  border-right: none;
}
.organization-tab__group .organization-tab__item .title {
  margin: 0;
  color: #134176;
  font-size: 18px;
}
.organization-tab__group .organization-tab__item .title span {
  font-weight: bold;
}
.organization-tab__group .organization-tab__item .subtitle {
  color: #6c757d;
  font-style: italic;
  margin: 0;
}
.organization-tab__group .organization-tab__item .switch__block {
  margin-top: 14px;
  display: flex;
  align-items: center;
}
.organization-tab__group .organization-tab__item .switch__block .ant-switch {
  margin-right: 10px;
}
.organization-tab__group .organization-tab__item .switch__block .ant-switch-checked {
  background: #21416F;
}
.organization-tab__group .organization-tab__item .clear__all {
  display: block;
  margin-top: 14px;
  font-size: 14px;
  line-height: 18px;
  color: #C82922;
  cursor: pointer;
}
.organization-tab__group .organization-tab__item .input {
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  max-width: 314px;
}
.organization-tab__group .organization-tab__item .input input {
  color: #134176;
}
.organization-tab__group .organization-tab__item .input input::placeholder {
  color: #134176;
}
.organization-tab__group .organization-tab__item .input svg {
  color: #21416F;
}
.organization-tab__group .organization-tab__item .organization-block__content {
  width: 85%;
  margin-top: 40px;
}
.organization-tab__group .organization-tab__item .organization-block__content .ant-input-search {
  margin-top: 40px;
}
.organization-tab__group .organization-tab__item .organization-block__content .ant-input-affix-wrapper, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-wrapper input {
  border: none;
}
.organization-tab__group .organization-tab__item .organization-block__content .ant-input-affix-wrapper:focus, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-affix-wrapper:hover, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-affix-wrapper:active, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-wrapper input:focus, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-wrapper input:hover, .organization-tab__group .organization-tab__item .organization-block__content .ant-input-wrapper input:active {
  border: none;
  box-shadow: none;
}
.organization-tab__group .organization-tab__item .organization-block__content .ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.organization-tab__group .organization-tab__item .organization-block__content .description {
  margin-bottom: 25px;
  width: 85%;
  color: #134176;
}
.organization-tab__group .organization-tab__item .comparison-group__input {
  width: 85%;
  color: #134176;
  padding: 10px 0 10px 5px;
}
.organization-tab__group .organization-tab__item .comparison-group__input::placeholder {
  color: #134176;
  font-size: 16px;
}
.organization-tab__group .organization-tab__item .ant-input::placeholder {
  padding-left: 3px;
  font-size: 16px;
}
.organization-tab__group .organization-tab__item .select-group {
  margin-top: 20px;
  margin-bottom: 30px;
}
.organization-tab__group .organization-tab__item .select-group p {
  margin-bottom: 0;
}
.organization-tab__group .organization-tab__item .select-group .select__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organization-tab__group .organization-tab__item .select-group .ant-select .ant-select-selector {
  padding: 6px 34px 7px 10px;
  height: 100%;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  color: #134176;
}
.organization-tab__group .organization-tab__item .level-group {
  margin-top: 10px;
  margin-bottom: 0;
}
.organization-tab__group .organization-tab__item .checkbox-group__comparison {
  min-height: 400px;
  max-height: 480px;
  overflow-y: auto;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.organization-tab__group .organization-tab__item .checkbox-group__comparison .ant-checkbox + span {
  font-size: 16px;
}
.organization-tab__group .organization-tab__item .checkbox-group__comparison .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #134176;
  border-color: #134176;
}
.organization-tab__group .organization-tab__item .checkbox-group__comparison .ant-checkbox-wrapper {
  margin: 0 0 10px;
}
.organization-tab__group .organization-tab__item .label {
  margin: 0;
  font-size: 12px;
  line-height: 23px;
}
.organization-tab__group .organization-tab__item .add-organization {
  margin-top: 15px;
  width: 100%;
  max-width: 300px;
  background: none;
  border-radius: 25px;
  padding: 10px 0;
  text-align: center;
  border: 1px solid #21416F;
  color: #21416F;
  transition: 0.3s all;
}
.organization-tab__group .organization-tab__item .add-organization:hover {
  color: #fff;
  background: #144176;
}
.organization-tab__group .organization-tab__item .comparison-group__list {
  max-height: 560px;
  overflow-y: auto;
  padding: 0;
  margin: 15px 0 0;
}
.organization-tab__group .organization-tab__item .comparison-group__list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  list-style: none;
}
.organization-tab__group .organization-tab__item .ant-select {
  width: 85%;
  color: #6c757d;
}
.organization-tab__group .comparison {
  overflow: hidden;
  background: #FEFEDC;
  border-left: 1px solid #EFEFEF;
}
.organization-tab__group .filters__block {
  padding-right: 10px;
  max-height: 450px;
  overflow: auto;
}
.organization-tab__group .filter {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.organization-tab__group .filter .filter__add {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 22px;
  height: 22px;
  margin-right: 8px;
  background: #21416F;
  border-radius: 50%;
  color: #fff;
}
.organization-tab__group .filter .filter__title {
  font-size: 14px;
  line-height: 18px;
  color: #21416F;
}

.delete-group {
  margin-right: 10px;
  position: relative;
  width: 14px;
  height: 14px;
  transition: 0.4s all;
  cursor: pointer;
}
.delete-group:hover {
  opacity: 0.4;
}
.delete-group:after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}
.delete-group:before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}

.button-block__modal {
  position: absolute;
  right: 10px;
  bottom: 75px;
  display: flex;
  justify-content: flex-end;
}
.button-block__modal button {
  font-size: 14px;
  line-height: 18px;
  background: #134176;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #21416F;
  padding: 8px 22px;
  transition: 0.3s all;
}
.button-block__modal button:hover {
  opacity: 0.4;
}
.button-block__modal button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.button-block__modal .button__cancel {
  border: none;
  color: #C82922;
  font-size: 14px;
  line-height: 18px;
  background: transparent;
}
.button-block__modal .button__cancel:hover {
  color: #8b0a02;
  opacity: 1;
}

.ant-popover .ant-popover-inner-content {
  padding: 12px 18px;
}

.popover__filter {
  max-height: 350px;
  overflow: auto;
}
.popover__filter .popover__title {
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 23px;
}
.popover__filter .popover__list {
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
}
.popover__filter .popover__list li {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 23px;
  color: #21416F;
  cursor: pointer;
  transition: 0.3s all;
}
.popover__filter .popover__list li:hover {
  opacity: 0.4;
}