//
//  modalTest.scss
//

.containerModalTest {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;

  .modalHeader {
    width: 100vw;
    height: 100%;
    min-height: ($topbar-height + 2.5px);
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-box {
      width: 215px;
      height: ($topbar-height + 2.5px);

      .logo-lg img {
        width: 215px;
        height: ($topbar-height + 2.5px);
        object-fit: contain;
        object-position: center;
      }
    }

    .contentHeader {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;

      @media screen and (min-width: 1024px) {
        justify-content: space-between;
      }

      .modalTitle {
        white-space: nowrap;
        max-width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 39px;
        font-size: 30px;
        line-height: 1.3;
        color: #000000;
        margin-left: 20px;
        margin-right: 20px;

        @media screen and (min-width: 1024px) {
          margin-right: 0;
        }
      }

      .containerButton {
        display: flex;
        align-items: center;

        button {
          margin-right: 10px;
          border: solid 1px #134176;
          border-radius: 21px;
          padding: 0 22px;
          height: 35px;
          width: 100%;

          span {
            font-size: 14px;
            color: #21416f;
            line-height: 18px;
          }

          &:hover {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .containerSidebar {
    position: absolute;
    bottom: 0;
    top: 77px;
    left: 0;
    overflow-y: auto;
    min-height: calc(100vh - 77px);
    width: 215px;
    background: #f9fcfe;
    border-right: 1px solid #dddddd;

    .showSidebar {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;

      span {
        font-size: 16px;
        line-height: 1.25;
        color: #000000;
      }
    }

    .backToQuestionnaires {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      border-bottom: 1px solid #dddddd;

      a {
        font-size: 16px;
        line-height: 1.25;
        color: #000000;
      }
    }

    .containerLists {
      overflow: auto;

      .containerListLink {
        display: block;
        padding: 27px 0px 32px;
        margin: 0 18px 0 16px;
        border-bottom: 1px solid #ddd;

        .containerListLink{
          
          border: none;
          padding: 0;
          margin: 0;
        }

        .titleLink {
          color: #000;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 20px;
        }
        .listLink {
          color: #134176;
          font-size: 12px;
          margin-left: 5px;

          .activeLink {
            text-decoration: none;
            color: #d92612;
          }

          a {
            word-wrap: break-word;
            display: block;
            text-decoration: underline;
            color: #134176;
            margin-bottom: 9px;
          }
        }
      }
    }
  }

  .containerContent {
    position: absolute;
    top: 77px;
    left: 215px;
    bottom: 0;
    width: calc(100vw - 215px);
    min-height: calc(100vh - 77px);
    overflow-y: auto;
    background: white;

    .containerFillter {
      height: 77px;
      border-bottom: 1px solid #dddddd;
      padding: 0 325px 0 110px;
      display: flex;
      justify-content: space-between;

      .rightContainer {
        display: flex;
        align-items: center;
      }

      .leftContainer {
        display: flex;
        align-items: center;
        .title {
          margin-right: 10px;
          font-size: 16px;
          line-height: 1.25;
          color: #000000;
        }
      }
    }
    .containerMainQuestionnaire {
      width: 100%;
      padding: 0 110px;
    }
  }

  .containerProgressbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 63.5px;

    .ant-progress-inner {
      background: #dddddd !important;
    }
  }
}

.containerLinkCollapse {
  border-top: 1px solid #ddd;
  margin-top: -1px;
}

.containerSpin {
  position: absolute;
  top: 77px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tag.ant-tag-checkable {
  cursor: pointer;
  &:hover {
    background-color: #dadada;
  }
}
.ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
  background-color: #ebedf1;
  cursor: pointer;

  &:after {
    content: "X";
    margin-left: 10px;
    cursor: pointer;
  }

  &:hover {
    background-color: #dadada;
  }
}
