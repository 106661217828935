.mainWrapper {
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  border: solid 1px #dddddd;
  background-color: #ffffff;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    background: #eeeeee;

    p {
      text-align: center;
      width: 33%;
    }
  }

  .multi__organizations {
    padding: 20px 0;
    display: flex;
    justify-content: center;

    .title {
      width: 33%;
      text-align: center;
    }
    .content {
      width: 33%;
      text-align: center;
    }
    .status {
      width: 33%;
      text-align: center;
    }
  }

  .indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 1.43;
    color: #7a8295;
    margin-bottom: 11px;

    svg {
      width: 40px;
      height: 40px;
      cursor: pointer;
      transition: .3s all;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .footerWrapper {
    height: 65px;
    width: calc(100% + 40px);
    margin: 20px -20px -20px -20px;
    padding: 0 20px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .containerTags {
      display: flex;

      .tagItem {
        border-radius: 5px;
        background-color: #ebedf1;
        margin-right: 10px;
        padding: 5px 10px;
      }
    }
    .containerButton {
      display: flex;
      color: #21416f;
      font-size: 14px;
      line-height: 23px;

      .comments {
        cursor: pointer;
      }

      .info {
        cursor: pointer;
      }
    }
  }
}

.tooltipModal {
  position: absolute;
  right: 60px;
  margin-top: -85px;
  width: 250px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dddddd;
  background-color: #fffedf;

  .triangle {
    width: 10px;
    height: 10px;
    background: #fffedf;
    transform: rotate(45deg);
    margin: 10px 0 0 -5px;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .iconClose {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .contant {
    font-size: 14px;
    line-height: 1.29;
    color: #333333;
    margin: 10px;
  z-index: 1000;
  }
}

.tooltip__list {
  padding-left: 0;
  list-style: none;

  li {
    font-size: 12px;
  }
}