.benchmark-block {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding-top: 32px;

  .column {
    padding: 0 10px;
    widht: 25%;
  }

  .category {
    width: 25%;
    border-left: 1px solid #EFEFEF;
  }

  .question-group {
    padding: 0 10px;
    width: 55%;
    border-left: 1px solid #EFEFEF;
  }

  .block-title {
    padding: 0 10px;
    font-style: italic;
    font-size: 14px;
    line-height: 23px;
    margin: 0;
    color: #7A8295;
  }

  .block-subtitle {
    max-width: 300px;
    padding: 0 10px;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
}