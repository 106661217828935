.organization-tab__group {
  display: flex;
  justify-content: space-between;

  .organization-tab__item {
    width: 25%;
    padding: 0 15px;
    border-right: 1px solid #EFEFEF;

    &:last-child {
      border-right: none;
    }

    .title {
      margin: 0;
      color: #134176;
      font-size: 18px;

      span {
        font-weight: bold;
      }
    }

    .subtitle {
      color: #6c757d;
      font-style: italic;
      margin: 0;
    }

    .switch__block {
      margin-top: 14px;
      display: flex;
      align-items: center;

      .ant-switch {
        margin-right: 10px;
      }

      .ant-switch-checked {
        background: #21416F;
      }

    }

    .clear__all {
      display: block;
      margin-top: 14px;
      font-size: 14px;
      line-height: 18px;
      color: #C82922;
      cursor: pointer;
    }

    .input {
      border: 2px solid #A0A7B7;
      border-radius: 4px;
      max-width: 314px;

      input {
        color: #134176;

        &::placeholder {
          color: #134176;
        }
      }

      svg {
        color: #21416F;
      }

    }

    .organization-block__content {
      width: 85%;
      margin-top: 40px;

      .ant-input-search {
        margin-top: 40px;
      }

      .ant-input-affix-wrapper , .ant-input-wrapper input {
        border: none;

        &:focus, &:hover, &:active {
          border: none;
          box-shadow: none;
        }
      }

      .ant-input-search-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }

      .description {
        margin-bottom: 25px;
        width: 85%;
        color: #134176;
      }
    }

    .comparison-group__input {
      width: 85%;
      color: #134176;
      padding: 10px 0 10px 5px;

      &::placeholder {
        color: #134176;
        font-size: 16px;
      }
    }

    .ant-input {
      &::placeholder {
        padding-left: 3px;
        font-size: 16px;
      }
    }

    .select-group {
      margin-top: 20px;
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }

      .select__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .ant-select {
        .ant-select-selector {
          padding: 6px 34px 7px 10px;
          height: 100%;
          border: 2px solid #A0A7B7;
          border-radius: 4px;
          color: #134176;
        }
      }
    }

    .level-group {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .checkbox-group__comparison {
      min-height: 400px;
      max-height: 480px;
      overflow-y: auto;
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      .ant-checkbox + span {
        font-size: 16px;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #134176;
        border-color: #134176;
      }

      .ant-checkbox-wrapper {
        margin: 0 0 10px;
      }
    }

    .label {
      margin: 0;
      font-size: 12px;
      line-height: 23px;
    }

    .add-organization {
      margin-top: 15px;
      width: 100%;
      max-width: 300px;
      background: none;
      border-radius: 25px;
      padding: 10px 0;
      text-align: center;
      border: 1px solid #21416F;
      color: #21416F;
      transition: .3s all;

      &:hover {
        color: #fff;
        background: #144176;
      }
    }

    .comparison-group__list {
      max-height: 560px;
      overflow-y: auto;
      padding: 0;
      margin: 15px 0 0;

      li {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;
        list-style: none;
      }
    }

    .ant-select {
      width: 85%;
      color: #6c757d;
    }
  }

  .comparison {
    overflow: hidden;
    background: #FEFEDC;
    border-left: 1px solid #EFEFEF;
  }

  .filters__block {
    padding-right: 10px;
    max-height: 450px;
    overflow: auto;
  }

  .filter {
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .filter__add {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      width: 22px;
      height: 22px;
      margin-right: 8px;
      background: #21416F;
      border-radius: 50%;
      color: #fff;
    }

    .filter__title {
      font-size: 14px;
      line-height: 18px;
      color: #21416F;
    }
  }
}

.delete-group {
  margin-right: 10px;
  position: relative;
  width: 14px;
  height: 14px;
  transition: .4s all;
  cursor: pointer;

  &:hover {
    opacity: .4;
  }

  &:after {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    border: 1px solid #C82922;
    background: #C82922;
    top: 6px;
  }

  &:before {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    border: 1px solid #C82922;
    background: #C82922;
    top: 6px;
  }
}

.button-block__modal {
  position: absolute;
  right: 10px;
  bottom: 75px;
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 14px;
    line-height: 18px;
    background: #134176;
    color: #fff;
    border-radius: 25px;
    border: 1px solid #21416F;
    padding: 8px 22px;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }

    &:disabled {
      opacity: .4;
      cursor: not-allowed;
    }
  }

  .button__cancel {
    border: none;
    color: #C82922;
    font-size: 14px;
    line-height: 18px;
    background: transparent;

    &:hover {
      color: #8b0a02;
      opacity: 1;
    }
  }
}

.ant-popover {

  .ant-popover-inner-content {
    padding: 12px 18px;
  }
}


.popover__filter {
  max-height: 350px;
  overflow: auto;

  .popover__title {
    margin-bottom: 6px;
    font-size: 18px;
    line-height: 23px;
  }

  .popover__list {
    margin-bottom: 24px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 23px;
      color: #21416F;
      cursor: pointer;
      transition: .3s all;

      &:hover {
        opacity: .4;
      }
    }

  }
}