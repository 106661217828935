.warning__wrapper {
  margin: 15px 0;
  padding: 30px 20px;
  border: 1px solid orange;
}
.warning__wrapper .title {
  font-weight: bold;
}
.warning__wrapper .text {
  display: flex;
}
.warning__wrapper .text span {
  margin-top: 5px;
  margin-right: 5px;
  max-height: 14px;
  background: orange;
  border-radius: 50%;
}
.warning__wrapper .text svg {
  fill: white;
}