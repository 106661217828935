.pageBenchmark {
  display: flex;
  width: 100%;
  padding: 100px 0 0 0;
}
.pageBenchmark .mainForm {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -100px;
  width: 100%;
  height: calc(100vh - 129px);
}
.pageBenchmark .mainForm button {
  width: 30%;
  height: 100%;
  max-height: 200px;
  font-size: 28px;
}
.pageBenchmark .mainForm button:active {
  color: #5273a0;
}
.pageBenchmark .mainForm button:focus {
  color: #21416f;
  border-color: #21416f;
}
.pageBenchmark .mainForm button:hover {
  border-color: #21416f;
  color: #21416f;
  font-size: 30px;
}
.pageBenchmark .containerLeft {
  margin-right: 50px;
  max-width: 220px;
}
.pageBenchmark .containerLeft .ItemLeft {
  margin-bottom: 50px;
}
.pageBenchmark .containerLeft .ItemLeft .containerButton {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 220px;
}
.pageBenchmark .containerLeft .ItemLeft .ant-calendar-picker input {
  border-color: #a0a7b7 !important;
  color: #134176;
}
.pageBenchmark .containerRight {
  width: 100%;
}
.pageBenchmark .containerRight .containerSearch .ant-input-search.ant-input-search-enter-button.ant-input-search-large.ant-input-group-wrapper.ant-input-group-wrapper-lg .ant-input-wrapper.ant-input-group .ant-input {
  height: 35px !important;
}
.pageBenchmark .containerRight .containerSearch .ant-input-search.ant-input-search-enter-button.ant-input-search-large.ant-input-group-wrapper.ant-input-group-wrapper-lg .ant-input-wrapper.ant-input-group .ant-input:hover {
  border-color: #21416f !important;
}
.pageBenchmark .containerRight .containerSearch .ant-input-search.ant-input-search-enter-button.ant-input-search-large.ant-input-group-wrapper.ant-input-group-wrapper-lg .ant-input-wrapper.ant-input-group .ant-input:focus {
  box-shadow: 0 0 3px #21416f;
  border-color: #21416f !important;
}
.pageBenchmark .containerRight .containerSearch .ant-input-search.ant-input-search-enter-button.ant-input-search-large.ant-input-group-wrapper.ant-input-group-wrapper-lg .ant-input-wrapper.ant-input-group .ant-input-group-addon button {
  background: #21416f;
  border-color: #21416f;
  height: 35px !important;
}
.pageBenchmark .containerRight .questiannaire {
  font-size: 18px;
  margin-top: 40px;
}
.pageBenchmark .containerRight .questian {
  margin-top: 30px;
  font-size: 24px;
}
.pageBenchmark .containerRight .containerButton {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 50px;
}
.pageBenchmark .containerRight .containerButton div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #21416f;
  width: 240px;
  height: 120px;
  padding: 40px;
  transition: 0.5s;
  font-size: 30px;
  cursor: pointer;
}
.pageBenchmark .containerRight .containerButton div:hover {
  box-shadow: 0 0 10px #21416f;
}
.pageBenchmark .containerRight .path {
  text-align: left;
  margin: 50px 0;
}
.pageBenchmark .containerRight .categories .ant-pagination {
  float: none !important;
}
.pageBenchmark .containerRight .customFormulars .ant-input {
  height: 40px;
  margin-bottom: 20px;
}
.pageBenchmark .containerRight .customFormulars .ant-input:hover {
  border-color: #21416f !important;
}
.pageBenchmark .containerRight .customFormulars .ant-input:focus {
  box-shadow: 0 0 2px #21416f;
  border-color: #21416f !important;
}
.pageBenchmark .containerRight .customFormulars .title, .pageBenchmark .containerRight .categoriesItem .title {
  font-size: 20px;
  font-weight: 900;
}
.pageBenchmark .containerRight .customFormulars .switches, .pageBenchmark .containerRight .categoriesItem .switches {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
.pageBenchmark .containerRight .customFormulars .switches .item, .pageBenchmark .containerRight .categoriesItem .switches .item {
  border: 2px solid #21416f;
  padding: 5px;
  margin-left: 10px;
}