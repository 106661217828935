.c-customInputNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-customInputNumber__prefix, .c-customInputNumber__suffix {
  background-color: #fafafa;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  border: 1px solid #d9d9d9;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  white-space: nowrap;
}
.c-customInputNumber__prefix {
  border-right: 0;
  border-radius: 2px 0 0 2px;
}
.c-customInputNumber__suffix {
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.c-customInputNumber__input {
  flex-grow: 1;
  border-radius: 0 !important;
}

.ant-legacy-form-item-children {
  display: block;
}

.c-customInputNumber + .ant-legacy-form-item-children-icon {
  right: 86px !important;
  margin-top: 0 !important;
  height: calc(100% - 3px) !important;
  transform: translateY(-50%);
  z-index: 99999 !important;
  background-color: #fff;
}