.list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  padding: 23px 26px;
  border: 1px solid #D8D8D8;
  cursor: pointer;
  transition: 0.3s all;
}
.list__item:hover {
  background: #eeeeb3;
}

.list__title {
  margin: 0;
  color: #1E3968;
  font-size: 30px;
  line-height: 39px;
}

.list__actions {
  display: flex;
  align-items: center;
}

.list__button {
  padding: 9px 22px;
  border: 1px solid #21416F;
  background: #fff;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  border-radius: 21px;
  opacity: 1;
  transition: 0.3s all;
}
.list__button:hover {
  opacity: 0.4;
}