.modal__view .view__label {
  margin-bottom: 3px;
  font-weight: bold;
}
.modal__view input {
  border: 1px solid #9e9e9e;
  border-radius: 2px;
}
.modal__view input:hover {
  border-color: #21416F;
}
.modal__view input::placeholder {
  font-size: 14px;
}
.modal__view .action__block {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal__view .button {
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  transition: 0.3s all;
  cursor: pointer;
}
.modal__view .button:hover {
  opacity: 0.4;
}
.modal__view .button__cancel {
  margin-right: 10px;
  border: 1px solid #9c3434;
  background: #9c3434;
}
.modal__view .button__save {
  border: 1px solid #21416F;
  background: #21416F;
}