.user__create {
  position: relative;
  padding-top: 46px;
  padding-right: 100px;

  .create__title {
    margin-bottom: 32px;
    font-size: 48px;
    line-height: 62px;
    color: #000000;
  }

  .feedback {
    padding: 7px 25px;
    border: 1px solid #134176;
    border-bottom: none;
    position: fixed;
    font-size: 16px;
    line-height: 20px;
    color: #134176;
    top: 50%;
    left: 100%;
    transform: translateX(-64%) rotate(-90deg);
    cursor: pointer;
  }
}