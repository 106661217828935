.popover__group_select {
  padding: 10px 40px;

  .group__block {
    display: flex;
  }

  .close__popover {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 18px;
    color: #a52222;
    border: 1px solid #a52222;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      background: #a52222;
      color: #fff;
    }
  }

  .group__item {
    .group__title {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 23px;
    }

    .ant-radio-group {
      max-height: 500px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .ant-radio-wrapper {
        display: flex;
        padding-bottom: 18px;
        margin-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;

        > span:not(.ant-radio) {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        span {
          font-weight: 500;
          line-height: 18px;

          svg {
            color: #1E3968;
          }
        }
      }

      .anticon-edit {
        margin-left: 36px;
      }

      .anticon-delete {
        margin-left: 16px;
      }
    }
    .group__action {
      display: flex;
      margin: 50px auto 0;
      padding: 10px 30px;
      border: 1px solid #21416F;
      background: transparent;
      outline: none;
      border-radius: 24px;
      color: #21416F;
      font-size: 14px;
      line-height: 18px;
      transition: .3s all;

      &:hover {
        background: #21416F;
        color: #fff;
      }
    }
  }
}

.select__comparison-group {
  margin-right: 10px;

  .report__organization--popover {
    display: flex;
    align-items: center;
    padding: 11px 12px;
    font-size: 14px;
    line-height: 23px;
    color: #134176;
    border: 2px solid #A0A7B7;
    border-radius: 4px;
    cursor: pointer;

    svg {
      margin-left: 50px;
      color: #21416F;
    }
  }

  .ant-popover-open {
    border: 2px solid #207BF8;

    svg {
      transform: rotate(180deg);
    }
  }

  .ant-select-selector.ant-select-selector {
    height: 100%;
    padding-left: 0;
  }

  .ant-select .ant-select-selector {
    padding: 5px 12px 5px 10px;
    height: 100%;
    border: 2px solid #A0A7B7;

    .ant-select-selection-item {
      color: #134176;
      padding-right: 50px;
    }
  }

  .ant-select .ant-select-selection-item {
    color: #134176;
    font-weight: normal;
    font-size: 14px;
  }

  .ant-select-arrow {
    font-size: 12px;
    color: #21416F;
    top: 50%;
  }
}