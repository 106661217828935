.containerSwitch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
  margin-top: 27px;
  width: 100%;
}
.containerSwitch .switchItem {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.containerSwitch .switchItem .switchItem_text {
  display: block;
}
.containerSwitch .switchItem .switchItem_text span {
  color: #134176;
  display: block;
  font-size: 16px;
  line-height: 1.13;
  max-width: 265px;
  text-decoration: underline;
}
.containerSwitch .switchItem .switchItem_text .title {
  color: #7a8295;
  font-size: 14px;
  text-decoration: none;
}