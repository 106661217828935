.file-manager__header {
  padding: 30px 0 20px;
  display: block;
  justify-content: space-between;
  align-items: center;
}
.file-manager__header .ant-input-group-wrapper {
  width: 25%;
}
.file-manager__header .select__organization {
  width: 300px;
}
.file-manager__header .search__block {
  display: block;
  text-align: right;
  margin-bottom: 10px;
}
.file-manager__header .actions__block {
  display: flex;
}
.file-manager__header .actions__block:first-child {
  margin-right: 10px;
}
.file-manager__header .form__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-manager__header .import__file {
  display: flex;
  align-items: center;
}
.file-manager__header .import__file .custom__input {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 4px 15px;
  border: 1px solid #313131;
}
.file-manager__header .import__file .custom__input span {
  margin-right: 5px;
}
.file-manager__header .import__file input {
  position: absolute;
  opacity: 0;
}
.file-manager__header .import__file .upload__name {
  margin: 0 10px 0 0;
}
.file-manager__header .download__all {
  display: flex;
  align-items: center;
  margin-left: 15px;
}