//
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $shadow-sm;

    .card-drop {
        font-size: 20px;
        line-height: 0;
        color: inherit;
    }
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F415";
                }
            }
        }
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($white, 0.8);
    cursor: progress;

    .card-portlets-loader {
        background-color: $dark;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -($grid-gutter-width / 2);
        margin-top: -($grid-gutter-width / 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card box
.card-box {
    background-color: $white;
    padding: $card-spacer-x;
    box-shadow: $shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $gray-600;
}

.containerCardQuestionnaire {
    display: flex;

    .ant-typography {
        .questions {
            display: flex;
            align-items: center;
        }
    }
}

.containerReport {
    height: 432px;
    background: #f9fcfe;

    .reportTitle {
        font-size: 30px;
        line-height: 1.2;
        color: #21416f;
        font-family: "IBM Plex Serif";
    }

    .dateCreate {
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 90%;

        span {
            display: flex;
            align-items: center;
            color: #333;
            font-style: italic;
        }
    }

    .imageContainerReport {
        position: absolute;
        left: calc(50% - 50px);
        top: calc(50% - 85px);
    }
}

.CardModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: auto;
    background: white;
    z-index: 1000;

    .modalHeader {
        width: 100%;
        height: ($topbar-height + 2.5px);
        background: white;
        border-bottom: 1px solid #dddddd;

        .logo-box {
            width: 215px;
            height: ($topbar-height + 2.5px);

            .logo-lg img {
                width: 215px;
                height: ($topbar-height + 2.5px);
                object-fit: contain;
                object-position: center;
            }
        }

        .contentHeader {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;

            .modalTitle {
                height: 39px;
                font-size: 30px;
                line-height: 1.3;
                color: #000000;
                margin-left: 110px;
            }

            .containerButton {
                display: flex;
                align-items: center;

                .selectDownload {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    a {
                        color: #134176;
                    }

                    .title {
                        display: block;
                        font-family: "Open Sans";
                        font-size: 14px;
                        line-height: 1.64;
                        color: #134176;
                        width: 85px;
                        margin-right: 10px;

                        &:hover {
                            border-bottom: 1px solid #21416f;
                            margin-bottom: -1px;
                        }
                    }

                    .ant-select-selection--single {
                        border: none !important;
                    }
                }

                button {
                    margin-right: 10px;
                    border: solid 1px #134176;
                    border-radius: 21px;
                    padding: 0 22px;
                    height: 35px;
                    width: 100%;

                    span {
                        font-size: 14px;
                        color: #21416f;
                        line-height: 18px;
                    }

                    &:hover {
                        opacity: 0.4;
                    }
                }
            }
        }
    }

    .buttonRight {
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 78px;
        border: 1px solid #dddddd;
        background: #f9fcfe;
    }

    .containerSidebar {
        height: calc(100vh - 72.5px);
        width: 215px;
        background: #f9fcfe;
        border-right: 1px solid #dddddd;

        .backToQuestionnaires {
            height: 78px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            border-bottom: 1px solid #dddddd;

            a {
                font-size: 16px;
                line-height: 1.25;
                color: #000000;
            }
        }

        .showSidebar {
            height: 78px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            border-bottom: 1px solid #dddddd;

            span {
                font-size: 16px;
                line-height: 1.25;
                color: #000000;
            }
        }

        .listLink {
            display: block;
            padding: 26px 23px 0 23px;

            a {
                display: block;
                line-height: 2;
                font-size: 12px;
                color: #134176;
                text-decoration: underline;
            }
        }
    }

    .containerContent {
        position: absolute;
        top: 72.5px;
        height: calc(100vh - 72.5px);
        background: #f9fcfe;
        width: 100%;

        .containerIframe {
            margin: 20px;

            iframe {
                border-radius: 2px;
                background: white;
            }
            .footerIframe {
                width: 100%;
                height: 110px;
                background: white;
                border: 1px solid #ddd;
                border-top: none;
                margin-top: -6px;
                padding: 0 32px;

                .selectDownload {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 31px;
                    border-top: 2px solid #ddd;
                    height: 100%;
                    font-size: 14px;
                    color: #134176;

                    a {
                        color: #134176;
                    }

                    .title {
                        display: block;
                        font-family: "Open Sans";
                        font-size: 14px;
                        line-height: 1.64;
                        color: #134176;
                        width: 85px;
                        margin-right: 10px;

                        &:hover {
                            border-bottom: 1px solid #21416f;
                            margin-bottom: -1px;
                        }
                    }

                    .ant-select-selection--single {
                        border: none !important;
                    }
                }
            }
        }
    }

    .containerRelated {
        width: 100%;
        background: white;
        border-top: 1px solid #ddd;
        padding: 45px 313px 70px 337px;

        .containerRelatedCard {
            display: flex;
            justify-content: space-between;
            margin-top: 23px;
            margin-right: 0 !important;
        }

        .title {
            font-size: 24px;
            line-height: 1.33;
            color: #000000;
        }
    }
}

.containerSwitch {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 54px;

    .switchItem {
        display: flex;
        align-items: center;

        .switchItem_text {
            display: block;
            span {
                display: block;
                max-width: 265px;
                font-size: 16px;
                line-height: 1.13;
                color: #134176;
                text-decoration: underline;
            }
            .title {
                font-size: 14px;
                color: #7a8295;
                text-decoration: none;
            }
        }
    }
}
