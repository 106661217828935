@import "../../../../../../styles/colours";

.collapseHeaderSelect {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .containerCheckbox {
    background-color: #fff;
    border: solid 1px #ddd;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    height: 280px;
    margin: 30px -15px 0 0;
    position: absolute;
    width: 300px;
    z-index: 900;

    .helpElement {
      background: #fff;
      border-left: solid 1px #ddd;
      border-top: solid 1px #ddd;
      height: 10px;
      margin-top: -5px;
      position: absolute;
      right: 15px;
      transform: rotate(45deg);
      width: 10px;
    }

    &-contant {
      height: 100%;
      overflow-x: auto;
      padding: 15px;
      width: 100%;

      .title {
        color: #000;
        font-size: 18px;
        margin-bottom: 14px;
        max-width: 90%;
        position: relative;

        .anticon {
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 9999;
        }

        span {
          color: #333;
          font-size: 14px;
          font-style: italic;  
        } 
      }
    }
  }

  .containerDropdown {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: flex;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    justify-content: flex-end;
    width: 100%;

    img {
      height: 15px;
    }
  }
}

.containerLinkCollapse {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  height: 100%;
  padding: 26px 11px 26px 12px;

  .content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .title {
    color: #21416f;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.28;
    margin-right: 6%;
    width: 300px;
  }
}