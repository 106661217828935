.view__page {
  padding-top: 20px;

  .come__back {
    display: flex;
    align-items: center;
    color: #134176;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }

    .anticon-left {
      margin-right: 10px;
    }
  }

}