.popover__group_select {
  padding: 10px 40px;
}
.popover__group_select .group__block {
  display: flex;
}
.popover__group_select .close__popover {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
  color: #a52222;
  border: 1px solid #a52222;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s all;
}
.popover__group_select .close__popover:hover {
  background: #a52222;
  color: #fff;
}
.popover__group_select .group__item .group__title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
}
.popover__group_select .group__item .ant-radio-group {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.popover__group_select .group__item .ant-radio-group .ant-radio-wrapper {
  display: flex;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #D8D8D8;
}
.popover__group_select .group__item .ant-radio-group .ant-radio-wrapper > span:not(.ant-radio) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.popover__group_select .group__item .ant-radio-group .ant-radio-wrapper span {
  font-weight: 500;
  line-height: 18px;
}
.popover__group_select .group__item .ant-radio-group .ant-radio-wrapper span svg {
  color: #1E3968;
}
.popover__group_select .group__item .ant-radio-group .anticon-edit {
  margin-left: 36px;
}
.popover__group_select .group__item .ant-radio-group .anticon-delete {
  margin-left: 16px;
}
.popover__group_select .group__item .group__action {
  display: flex;
  margin: 50px auto 0;
  padding: 10px 30px;
  border: 1px solid #21416F;
  background: transparent;
  outline: none;
  border-radius: 24px;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  transition: 0.3s all;
}
.popover__group_select .group__item .group__action:hover {
  background: #21416F;
  color: #fff;
}

.select__comparison-group {
  margin-right: 10px;
}
.select__comparison-group .report__organization--popover {
  display: flex;
  align-items: center;
  padding: 11px 12px;
  font-size: 14px;
  line-height: 23px;
  color: #134176;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  cursor: pointer;
}
.select__comparison-group .report__organization--popover svg {
  margin-left: 50px;
  color: #21416F;
}
.select__comparison-group .ant-popover-open {
  border: 2px solid #207BF8;
}
.select__comparison-group .ant-popover-open svg {
  transform: rotate(180deg);
}
.select__comparison-group .ant-select-selector.ant-select-selector {
  height: 100%;
  padding-left: 0;
}
.select__comparison-group .ant-select .ant-select-selector {
  padding: 5px 12px 5px 10px;
  height: 100%;
  border: 2px solid #A0A7B7;
}
.select__comparison-group .ant-select .ant-select-selector .ant-select-selection-item {
  color: #134176;
  padding-right: 50px;
}
.select__comparison-group .ant-select .ant-select-selection-item {
  color: #134176;
  font-weight: normal;
  font-size: 14px;
}
.select__comparison-group .ant-select-arrow {
  font-size: 12px;
  color: #21416F;
  top: 50%;
}