.view__page {
  padding-top: 20px;
}
.view__page .come__back {
  display: flex;
  align-items: center;
  color: #134176;
  margin-bottom: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s all;
}
.view__page .come__back:hover {
  opacity: 0.4;
}
.view__page .come__back .anticon-left {
  margin-right: 10px;
}