.modal__charts .modal__indicators {
  padding-bottom: 26px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: #7A8295;
}
.modal__charts .modal__indicators svg {
  margin-left: 4px;
  width: 10px;
  height: 10px;
  color: #21416F;
}
.modal__charts .ant-tabs-nav-list .ant-tabs-tab {
  padding: 30px;
}
.modal__charts .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #1E3968;
}
.modal__charts .ant-tabs-nav-list .ant-tabs-tab-active {
  color: #000000;
  font-weight: bold;
}
.modal__charts .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #525566;
}
.modal__charts .tab__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}
.modal__charts .tab__header .header__info {
  display: flex;
}
.modal__charts .tab__header .info:first-child {
  margin-right: 44px;
}
.modal__charts .tab__header .info .text {
  margin: 0;
  color: #000000;
  font-size: 12px;
  line-height: 15px;
}
.modal__charts .tab__header .info .value {
  margin: 0;
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}
.modal__charts .tab__header .select__header {
  display: flex;
  align-items: center;
}
.modal__charts .tab__header .select__header .text {
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: 18px;
}
.modal__charts .tab__header .select__header .ant-select, .modal__charts .tab__header .select__header .ant-picker {
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  color: #134176;
}
.modal__charts .tab__header .select__header .ant-picker {
  margin-right: 10px;
}
.modal__charts .tab__header .select__header .ant-select-suffix {
  color: #21416F;
}
.modal__charts .loader__block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.modal__charts .modal__footer {
  margin-top: 20px;
}
.modal__charts .modal__footer h4 {
  margin-bottom: 5px;
}