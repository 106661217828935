.content .info__list {
  list-style: none;
  padding: 0;
}
.content .info__list--item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.content .info__list--item .label {
  width: 35%;
  margin: 0 5px 0 0;
  font-weight: bold;
  text-transform: uppercase;
}
.content .info__list--item .info {
  padding: 5px;
  border: 1px solid #bfbebe;
  border-radius: 5px;
  min-width: 300px;
  min-height: 30px;
}