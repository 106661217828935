.warning__wrapper {
  margin: 15px 0;
  padding: 30px 20px;
  border: 1px solid orange;

  .title {
    font-weight: bold;
  }

  .text {
    display: flex;

    span {
      margin-top: 5px;
      margin-right: 5px;
      max-height: 14px;
      background: orange;
      border-radius: 50%;
    }
    svg {
      fill: white;
    }
  }
}