.user__block {
  position: relative;
  padding-top: 46px;
  padding-bottom: 60px;
  padding-right: 100px;
}
.user__block .feedback {
  padding: 7px 25px;
  border: 1px solid #134176;
  border-bottom: none;
  position: fixed;
  font-size: 16px;
  line-height: 20px;
  color: #134176;
  top: 50%;
  left: 100%;
  transform: translateX(-64%) rotate(-90deg);
  cursor: pointer;
}
.user__block .user__actions {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user__block .user__edit-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 18px;
  color: #21416F;
  cursor: pointer;
  transition: 0.3s all;
}
.user__block .user__edit-action:hover {
  opacity: 0.5;
}
.user__block .user__edit-action span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  width: 38px;
  height: 38px;
  background: #134176;
  border-radius: 50%;
}
.user__block .user__edit-action svg {
  width: 20px;
  height: 20px;
  color: #fff;
}
.user__block .user__create-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 18px;
  color: #21416F;
  cursor: pointer;
  transition: 0.3s all;
}
.user__block .user__create-action:hover {
  opacity: 0.5;
}
.user__block .user__create-action span {
  position: relative;
  margin-left: 14px;
  width: 38px;
  height: 38px;
  background: #134176;
  border-radius: 50%;
}
.user__block .user__create-action span:after {
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.user__block .user__create-action span:before {
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%) rotate(90deg);
}
.user__block .user__list {
  padding: 0 0 32px;
  border: 1px solid #D8D8D8;
  list-style: none;
}
.user__block .user__list:first-child {
  padding-top: 8px;
}
.user__block .user__list .user__list-item {
  padding: 0 26px 0 12px;
  transition: 0.3s all;
}
.user__block .user__list .user__list-item:hover {
  background: #eeeeb3;
}
.user__block .user__list .pagination {
  padding: 32px 26px 0 12px;
}
.user__block .help-center {
  width: 70%;
}
.user__block .user__filters {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user__block .user__filters .filter__sort {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user__block .user__filters .filter__sort p {
  margin: 0 5px 0 0;
  font-size: 14px;
  line-height: 18px;
  color: #21416F;
}
.user__block .user__filters .filter__sort span {
  color: #21416F;
}
.user__block .user__filters .filter__search {
  width: 30%;
}
.user__block .user__filters .filter__search input {
  color: #333333;
}
.user__block .user__filters .filter__search input:focus, .user__block .user__filters .filter__search input:hover, .user__block .user__filters .filter__search input:active {
  border-color: #134176;
  box-shadow: none;
}
.user__block .user__filters .filter__search input::placeholder {
  font-size: 14px;
  line-height: 22px;
}
.user__block .user__filters .filter__search button {
  background: #134176;
  border: 1px solid #134176;
}

.button__apply {
  display: block;
  padding: 8px 26px;
  margin: 15px auto 0;
  height: auto;
  background: #1E3968;
  border: 1px solid #134176;
  outline: none;
  border-radius: 18px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition: 0.3s all;
}
.button__apply:hover {
  opacity: 0.5;
}