.content {

  .info__list {
    list-style: none;
    padding: 0;
  }

  .info__list--item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .label {
      width: 35%;
      margin: 0 5px 0 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    .info {
      padding: 5px;
      border: 1px solid #bfbebe;
      border-radius: 5px;
      min-width: 300px;
      min-height: 30px;
    }
  }
}