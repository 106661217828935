.report__filters--select .ant-select-selector.ant-select-selector .ant-select-selection-item {
  font-size: 14px;
  line-height: 23px;
}

.report__saves {
  transition: 0.3s all;
}
.report__saves:hover {
  opacity: 0.4;
}

.saved__views--option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.saved__views--option p {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 0;
}
.saved__views--option svg {
  cursor: pointer;
  transition: 0.3s all;
}
.saved__views--option svg:hover {
  fill: darkred;
  transform: scale(1.1);
}