.ant-collapse.ant-collapse {
  margin-bottom: 10px;
}

.collapse__block .ant-collapse-item {
  background: #F5F6F8;
}
.collapse__block .ant-collapse-item .ant-collapse-header {
  padding-right: 16px !important;
}
.collapse__block .ant-collapse-arrow {
  top: 30px !important;
}
.collapse__block button.ant-collapse-arrow {
  padding: 12px 18px;
  border: 1px solid #21416F;
  background: transparent;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  border-radius: 16px;
  transition: 0.3s all;
}
.collapse__block button.ant-collapse-arrow:hover {
  opacity: 0.4;
}

.panel__header .titles {
  padding-bottom: 10px;
  border-bottom: 1px solid #979DAD;
}
.panel__header .titles span {
  font-size: 12px;
  line-height: 15px;
  color: #525566;
  font-style: italic;
}
.panel__header .titles .report__title {
  font-size: 18px;
  line-height: 23px;
  color: #1E3968;
}
.panel__header .titles .report__title span {
  font-size: 18px;
  line-height: 23px;
  color: #1E3968;
  font-weight: bold;
  font-style: normal;
}
.panel__header .steps {
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.panel__header .steps .icons {
  margin-right: 8px;
  transform: rotate(90deg);
}
.panel__header .steps .step {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.panel__header .steps .step .step__title {
  margin: 0 10px 0 0;
  color: #1E3968;
  font-size: 14px;
  line-height: 30px;
}
.panel__header .steps .step svg {
  width: 12px;
  height: 12px;
  color: #346DF6;
}
.panel__header .steps .step:last-child svg {
  display: none;
}