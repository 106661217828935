.topbar__menu {
  min-width: 400px;
  max-height: 700px;
  overflow: scroll;

  .menu__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  .menu__info {
    display: inline-block;
    padding-right: 10px;
    cursor: pointer;
    transition: .3s all;

    svg {
      transition: .3s all;
    }

    &:hover {
      svg {
        font-size: 18px;
      }
    }
  }
}