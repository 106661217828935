.search-page {
  width: 100%;
  padding-bottom: 100px;
}
.search-page .come__back {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #134176;
  margin-bottom: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s all;
}
.search-page .come__back:hover {
  opacity: 0.4;
}
.search-page .come__back .anticon-left {
  margin-right: 10px;
}
.search-page .title {
  margin-bottom: 26px;
  font-size: 36px;
  line-height: 47px;
  color: #134176;
}
.search-page .search-result {
  margin-bottom: 8px;
  font-size: 30px;
  line-height: 39px;
  color: #134176;
}
.search-page .search-result span {
  color: #1477FC;
}
.search-page .input__block {
  margin-bottom: 28px;
}
.search-page .input__block .ant-select-auto-complete {
  width: 100%;
}
.search-page .input__block .ant-input-affix-wrapper {
  padding: 10px 10px 10px 13px;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  box-shadow: none;
  border-right: 2px solid #A0A7B7 !important;
}
.search-page .input__block .ant-input-affix-wrapper:hover, .search-page .input__block .ant-input-affix-wrapper:focus {
  border-right: 2px solid #A0A7B7 !important;
}
.search-page .input__block .checkbox-group {
  margin-top: 22px;
}
.search-page .input__block .ant-input-suffix span {
  color: #164274;
  cursor: pointer;
}
.search-page .input__block .ant-input-suffix span:first-child {
  margin-right: 14px;
}
.search-page .input__block .ant-input-suffix span svg {
  width: 15px;
  height: 15px;
}
.search-page .input__block .ant-input {
  font-size: 14px;
  line-height: 23px;
  color: #1E3968;
}
.search-page .input__block .ant-input::placeholder {
  font-size: 14px;
  line-height: 23px;
  color: #1E3968;
}
.search-page .input__block .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #134176;
  border-color: #134176;
}
.search-page .ant-pagination {
  margin: 20px 0 40px;
}