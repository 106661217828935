.mainWrapper {
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}
.mainWrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.mainWrapper .header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  background: #eeeeee;
}
.mainWrapper .header p {
  text-align: center;
  width: 33%;
}
.mainWrapper .multi__organizations {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.mainWrapper .multi__organizations .title {
  width: 33%;
  text-align: center;
}
.mainWrapper .multi__organizations .content {
  width: 33%;
  text-align: center;
}
.mainWrapper .multi__organizations .status {
  width: 33%;
  text-align: center;
}
.mainWrapper .indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.43;
  color: #7a8295;
  margin-bottom: 11px;
}
.mainWrapper .indicator svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s all;
}
.mainWrapper .indicator svg:hover {
  transform: scale(1.1);
}
.mainWrapper .footerWrapper {
  height: 65px;
  width: calc(100% + 40px);
  margin: 20px -20px -20px -20px;
  padding: 0 20px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mainWrapper .footerWrapper .containerTags {
  display: flex;
}
.mainWrapper .footerWrapper .containerTags .tagItem {
  border-radius: 5px;
  background-color: #ebedf1;
  margin-right: 10px;
  padding: 5px 10px;
}
.mainWrapper .footerWrapper .containerButton {
  display: flex;
  color: #21416f;
  font-size: 14px;
  line-height: 23px;
}
.mainWrapper .footerWrapper .containerButton .comments {
  cursor: pointer;
}
.mainWrapper .footerWrapper .containerButton .info {
  cursor: pointer;
}

.tooltipModal {
  position: absolute;
  right: 60px;
  margin-top: -85px;
  width: 250px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dddddd;
  background-color: #fffedf;
}
.tooltipModal .triangle {
  width: 10px;
  height: 10px;
  background: #fffedf;
  transform: rotate(45deg);
  margin: 10px 0 0 -5px;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.tooltipModal .iconClose {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.tooltipModal .contant {
  font-size: 14px;
  line-height: 1.29;
  color: #333333;
  margin: 10px;
  z-index: 1000;
}

.tooltip__list {
  padding-left: 0;
  list-style: none;
}
.tooltip__list li {
  font-size: 12px;
}