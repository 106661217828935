.account-pages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f9fcfe;
}
.account-pages .containerLogin {
  background: #ffffff;
  width: 320px;
  border: solid 1px #d8d8d8;
  padding: 16px;
  color: #333;
}
.account-pages .containerLogin .buttonLogin {
  width: 100%;
  border-radius: 21px;
  border: solid 1px #21416f;
  background-color: #21416f;
  margin: 0;
}
.account-pages .containerLogin .title {
  font-size: 24px;
  line-height: 1.25;
  color: #000000;
  padding-right: 10px;
  margin: 24px 0;
}
.account-pages .containerLogin .logo {
  width: 146px;
  height: 50px;
  margin-left: -10px;
}
.account-pages .containerLogin .logo img {
  width: 190px;
  height: 50px;
  object-fit: cover;
  object-position: left;
}
.account-pages .containerLogin .linkForgot {
  font-size: 14px;
  line-height: 1.29;
  color: #21416f;
  width: 100%;
  text-align: center;
}