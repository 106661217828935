.search-page {
  width: 100%;
  padding-bottom: 100px;

  .come__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #134176;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }

    .anticon-left {
      margin-right: 10px;
    }
  }

  .title {
    margin-bottom: 26px;
    font-size: 36px;
    line-height: 47px;
    color: #134176;
  }

  .search-result {
    margin-bottom: 8px;
    font-size: 30px;
    line-height: 39px;
    color: #134176;

    span {
      color: #1477FC;
    }
  }

  .input__block {
    margin-bottom: 28px;

    .ant-select-auto-complete {
      width: 100%;
    }

    .ant-input-affix-wrapper {
      padding: 10px 10px 10px 13px;
      border: 2px solid #A0A7B7;
      border-radius: 4px;
      box-shadow: none;
      border-right: 2px solid #A0A7B7 !important;

      &:hover, &:focus {
        border-right: 2px solid #A0A7B7 !important;
      }
    }

    .checkbox-group {
      margin-top: 22px;
    }

    .ant-input-suffix {

      span {
        color: #164274;
        cursor: pointer;

        &:first-child {
          margin-right: 14px;
        }

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    
    .ant-input {
      font-size: 14px;
      line-height: 23px;
      color: #1E3968;

      &::placeholder {
        font-size: 14px;
        line-height: 23px;
        color: #1E3968;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #134176;
      border-color: #134176;
    }

  }

  .ant-pagination {
    margin: 20px 0 40px;
  }
}