.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 50;
}
.loading .loading__blur {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
}
.loading .loading__content {
  padding: 48px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.loading .loading__content .loader {
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .loading__content .lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading .loading__content .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 10px solid #207BF8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #207BF8 transparent transparent transparent;
}
.loading .loading__content .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading .loading__content .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading .loading__content .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading .loading__content .loading__title {
  margin: 0;
  font-size: 18px;
  line-height: 14px;
  color: #000000;
}