.user__info-block {
  padding-bottom: 22px;
  display: flex;

  .img__block {
    position: relative;
    width: 64px;
    height: 64px;
    cursor: pointer;

    .avatar {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 50%;
      background: #7A8295;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
      }
    }

    input[type="file"] {
      width: 64px;
      height: 64px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }

    svg {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .text__block {
    margin-left: 14px;

    .name {
      margin: 0;
      font-size: 48px;
      line-height: 62px;
      color: #000000;
    }

    .last__visit {
      margin: 0;
      font-size: 12px;
      line-height: 23px;
      color: #333333;
      font-style: italic;
    }
  }
}

