@charset "UTF-8";
.collapseHeader {
  display: block;
  width: 100%;
}
.collapseHeader .collapseHeaderMain {
  align-items: center;
  display: flex;
  width: 100%;
}
.collapseHeader .collapseHeaderMain .contentСollapseHeader {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-style: italic;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}
.collapseHeader .collapseHeaderMain .title {
  color: #21416f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-right: 30px;
  width: 300px;
}
.collapseHeader .collapseHeaderSelect {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.collapseHeader .collapseHeaderSelect .containerDropdown {
  align-items: center;
  color: #333;
  cursor: pointer;
  display: flex;
  font-family: "Open Sans";
  font-style: italic;
  font-size: 14px;
  justify-content: flex-end;
  width: 100%;
}
.collapseHeader .collapseHeaderSelect .containerDropdown img {
  height: 15px;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox {
  background-color: #fff;
  border: solid 1px #ddd;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 280px;
  margin: 30px -15px 0 0;
  position: absolute;
  width: 300px;
  z-index: 900;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox .helpElement {
  background: #fff;
  border-left: solid 1px #ddd;
  border-top: solid 1px #ddd;
  height: 10px;
  margin-top: -5px;
  position: absolute;
  right: 15px;
  transform: rotate(45deg);
  width: 10px;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox-contant {
  height: 100%;
  overflow-x: auto;
  padding: 15px;
  width: 100%;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox-contant .title {
  color: #000;
  font-size: 18px;
  margin-bottom: 14px;
  max-width: 90%;
  position: relative;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox-contant .title .anticon {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}
.collapseHeader .collapseHeaderSelect .containerCheckbox-contant .title span {
  color: #333;
  font-size: 14px;
  font-style: italic;
}

.ant-collapse-content-box {
  padding: 0 16px !important;
}

.ant-collapse-content-box > .containerLinkCollapse:first-child {
  border-top: none;
}

.ant-collapse-content-box > .containerLinkCollapse:last-child {
  border-bottom: none;
}