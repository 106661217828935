.organization-page {

  .files__header {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-breadcrumb {

    .ant-breadcrumb-link {
      transition: .3s all;
      cursor: pointer;

      &:hover {
        opacity: .5;
      }
    }
  }

  .rules__block {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }

    p {
      margin: 0 10px 0 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}