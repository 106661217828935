.report-pages {
  width: 100%;
  padding-bottom: 40px;
}

.reports {
  width: 100%;

  .header {
    margin-bottom: 24px;
  }

  .step {
    padding-top: 22px;
  }

  .report-form {
    margin-top: 14px;
    margin-bottom: 15px;

    .ant-select-selector.ant-select-selector {
      height: 100%;
      padding-left: 0;
    }

    .ant-select .ant-select-selection-item {
      color: #134176;
      font-size: 36px;
      line-height: 47px;
     }

    .ant-select-arrow {
      font-size: 16px;
      color: #21416F;
    }
  }

  .report__filters {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #DDDDDD;
  }

  .report__filters-selects {
    display: flex;

    .report__selects {
      margin-right: 48px;

      &:last-child {
        margin-right: 0;
      }
    }

    p {
      margin: 0;
      font-style: italic;
      font-size: 14px;
      line-height: 23px;
      color: #7A8295;
    }
    .ant-select-selector.ant-select-selector {
      height: 100%;
      padding-left: 0;
      min-width: 200px;
    }

    .ant-select .ant-select-selection-item {
      color: #134176;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
    }

    .ant-select-arrow {
      font-size: 12px;
      color: #21416F;
      top: 50%;
    }
  }

  .report__filters-inputs {
    display: flex;
    align-items: center;

    .report__inputs {
      margin-right: 14px;
    }

    .report__filters--select {
      margin-right: 10px;
    }

    .custom__input {
      padding: 10px 26px 10px 12px;

      input {
        margin-right: 60px;
        color: #1E3968;
        opacity: .5;
        font-size: 14px;
        line-height: 23px;

        &::placeholder {
          color: #1E3968;
          font-size: 14px;
          line-height: 23px;
        }
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .ant-select .ant-select-selector {
      width: 250px;
      padding: 6px 34px 7px 10px;
      height: 100%;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        color: #134176;
        font-size: 14px;
        line-height: 23px;
      }
    }

    .ant-select-arrow {
      color: #21416F;
      font-size: 12px;
    }
  }

  .report__saves {
    display: flex;
    align-items: center;
    cursor: pointer;

    .report__saves--text {
      margin-bottom: 0;
      margin-right: 8px;
      color: #21416F;
      font-size: 12px;
      line-height: 15px;
    }

    svg {
      width: 18px;
      height: 18px;
      color: #21416F;
    }
  }
}

.report__organization {
  padding-top: 24px;
  display: flex;
  align-items: center;

  .report__organization--filters {
    margin-left: auto;
  }

  .report__your-organization {
    margin-right: 24px;

    .report__organization--popover {
      display: flex;
      align-items: center;
      padding: 11px 12px;
      font-size: 14px;
      line-height: 23px;
      color: #134176;
      border: 2px solid #A0A7B7;
      border-radius: 4px;
      cursor: pointer;

      p {
        margin: 0;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        margin-left: 50px;
        color: #21416F;
      }
    }

    .ant-popover-open {
      border: 2px solid #207BF8;

      svg {
        transform: rotate(180deg);
      }
    }

    .ant-select-selector.ant-select-selector {
      height: 100%;
      padding-left: 0;
    }

    .ant-select .ant-select-selector {
      padding: 5px 12px 5px 10px;
      height: 100%;
      border: 2px solid #A0A7B7;

      .ant-select-selection-item {
        color: #134176;
        padding-right: 50px;
      }
    }

    .ant-select .ant-select-selection-item {
      color: #134176;
      font-weight: normal;
      font-size: 14px;
    }

    .ant-select-arrow {
      font-size: 12px;
      color: #21416F;
      top: 50%;
    }
  }

  .report__organization-text {
    margin-right: 20px;
    font-size: 14px;
    line-height: 23px;
    font-style: italic;
    color: #7A8295;
  }

  .report__apply {
    display: block;
    margin-left: auto;
    margin-right: 30px;
    padding: 9px 20px;
    background: #21416F;
    border: 1px solid #21416F;
    color: #fff;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }

    &:disabled {
      opacity: 0.2;
      cursor: no-drop;
    }
  }

  .filter-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    color: #21416F;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }

    svg {
      color: #134176;
      margin-left: 6px;
    }
  }

  .filter-content {
    display: flex;
    align-items: center;

    .filter-popover {
      margin-left: 28px;

      img {
        margin-left: 6px;
      }
    }
  }
}

.report__search-filter {
  margin-top: 10px;
  display: flex;
  align-items: center;

  p {
    margin: 0 10px 0 0;
    font-size: 18px;
    line-height: 22px;
    color: #21416F;
  }

  span {
    cursor: pointer;
    color: #21416F;
  }
}

.report__tabs {
  margin-top: 46px;

  .download__benchmark {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-bottom: 30px;
    background: #134176;
    border-color: #134176;
    border-radius: 10px;
  }

  .ant-collapse-content-box.ant-collapse-content-box {
    padding: 0;
  }

  .tabs__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .columns {
      width: 20%;
      max-width: 170px;
      padding-left: 12px;
      padding-bottom: 22px;
      border-left: 1px solid #D8D8D8;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .median-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .table-img {
        width: 24px;
        height: 20px;
        color: #1E3968;
        cursor: pointer;
      }
    }
  }

  .report__item {
    margin-bottom: 6px;
    position: relative;
  }

  .all-report__item {

    .ant-collapse-header {
      background: #ededed;
    }
  }

  .ant-collapse-header {
    background: #DDE0E6;
    color: #000000!important;
    font-size: 18px;
    line-height: 23px;
  }

  .tab__title {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 12px;
    left: 12px;
    right: 12px;
  }

  .tab__title-text {
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .tab__title-content {
    display: flex;
    align-items: center;
    color: #21416F;
    font-size: 14px;
    line-height: 23px;
  }

  .map, .chart {
    margin-right: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .map__img {
      margin-right: 4px;
    }

    transition: .3s all;

    &:hover {
      opacity: .5;
    }
  }

  .info {
    display: flex;
    align-items: center;
    color: #7A8295;
    font-size: 12px;
    line-height: 20px;

    svg {
      margin-left: 4px;
    }
  }

  .favorite {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;

    svg {
      margin-left: 4px;
    }

    &:hover {
      color: #fadb14;
      cursor: pointer;
    }

    &.checked {
      color: #fadb14;
    }
  }

  .report__content {
    padding-top: 47px;
    padding-left: 12px;
    padding-bottom: 28px;
    width: 20%;
    border-left: 1px solid #D8D8D8;
    color: #000000;
    font-size: 18px;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;

    &:first-child {
      border-left: none;
    }
  }

  .report__content-block {
    position: relative;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(even) {
      background: #edf0f1;
    }
  }

  .ant-collapse-content-box {
    min-height: 50px;
    padding: 0;
  }
}

.help-center {
  width: 100%;
  margin-top: 40px;
  padding: 26px 26px 26px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DDDDDD;

  .help-center__title {
    margin-bottom: 12px;
    color: #000000;
    font-size: 24px;
    line-height: 32px;
  }

  .help-center__action {
    padding: 9px 18px;
    border: 1px solid #134176;
    color: #21416F;
    font-size: 14px;
    line-height: 18px;
    background: transparent;
    border-radius: 18px;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }
  }
}

.ant-modal-content {

  .ant-modal-header {
    padding-bottom: 40px;
    border-bottom: none;
  }

  .ant-modal-body {
    min-height: 85vh;
    padding-bottom: 38px;
  }

  .ant-modal-title {
    font-size: 38px;
    line-height: 49px;
    font-weight: bold;
    color: #000000;

    svg {
      color: #164274;
    }
  }
}

.report__modal {

  .ant-modal-content  {

    .ant-modal-title {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
    }

    .ant-modal-header {
      padding: 20px 20px 4px;
    }

    .ant-modal-body {
      padding: 0 20px;
    }
  }

  .ant-modal-close-x {
    svg {
      color: #21416F;
    }
  }

}