@import "../../styles/colours";

.elementUpdater {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  bottom: 2rem;
  display: flex;
  gap: 2rem;
  padding: 20px;
  position: absolute;
  right: 2rem;
  z-index: 1100;

  > span {
    font-weight: 600;
  }
}

.titleQuestionnaire {
  align-items: center;
  display: flex;
  height: 102px;
  justify-content: space-between;
  margin: 23.5px 0 26.5px 0;

  .titleQuestionnaire_left {
    .titel {
      color: #000;
      font-size: 48px;
      font-weight: 400;
      line-height: 1.29;
    }
  }

  .titleQuestionnaire_right {
    align-items: center;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 102px;
    justify-content: space-evenly;
    padding: 0 10px;
    width: 235px;

    .ant-btn.ant-btn-link {
      height: 100%;
    }

    button {
      margin: 0 auto;
      display: block;
    }

    div {
      width: 100%;
    }
  }
}

.qs-details-shown {
  right: 0 !important;

  @media (max-width: 1191px) {
    display: block !important;
  }
}

.showing-details {
  padding-right: 335px;
  transition: 0.3s;

  @media (max-width: 1191px) {
    padding-right: 0;
  }
}

.qs-details {
  background-color: #fff;
  border-left: 1px solid $color-gray;
  bottom: 0;
  overflow-y: auto;
  padding: 0 20px;
  position: fixed;
  right: -100%;
  top: 77px;
  transition: 0.3s;
  width: 300px;
  z-index: 1050;

  &-label {
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-comment {
    margin-bottom: 20px;

    .ant-comment {
      margin-bottom: 10px;

      .ant-comment-inner {
        padding: 0;

        .ant-comment-avatar {
          display: none;
        }

        .ant-comment-content {
          padding: 10px;

          .ant-comment-content-author {
            display: block;

            &-name {
              color: #000;
              display: block;
              font-size: 16px;
              font-weight: bold;
            }

            &-time {
              color: #000;
              font-size: 14px;
              font-style: italic;        
            }
          }

          .ant-comment-content-detail {
            color: #000;
            font-size: 14px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &-view-logs {
    list-style: none;
    margin-top: 20px;
    padding: 0;
  }

  @media (max-width: 1191px) {
    border: 1px solid $color-gray;
    display: none;
    margin-top: 20px;
    padding: 20px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }

  .iconClose {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .containerDetails {
    padding-top: 42px;

    .containerButton {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: space-between;

      button {
        background-color: #21416f;
        border: solid 1px #21416f;
        border-radius: 21px; 
        color: #fff;
        display: block;
        font-size: 14px;
        height: 35px;
      }
    }

    .title {
      color: #000;
      line-height: 1.33;
      margin-bottom: 30px;
    }

    .detail_textArea {
      border: 1px solid #7a8295;
      border-radius: 0px;
      resize: none;
    }
  }
}

.containerModalTest {
  background: white;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;  
  z-index: 999;

  .containerContent {
    background: white;
    bottom: 0;
    left: 215px;
    min-height: calc(100vh - 77px);
    overflow-y: auto;
    position: absolute;
    top: 77px;
    width: calc(100vw - 215px);

    .containerFillter {
      border-bottom: 1px solid #ddd;
      display: flex;
      height: 77px;
      justify-content: space-between;
      padding: 0 325px 0 110px;

      .rightContainer {
        align-items: center;
        display: flex;
      }

      .leftContainer {
        align-items: center;
        display: flex;

        .title {
          color: #000;
          font-size: 16px;
          line-height: 1.25;
          margin-right: 10px;
        }
      }
    }

    .containerMainQuestionnaire {
      padding: 0 110px;
      width: 100%;
    }
  }

  .containerProgressbar {
    left: 0;
    position: absolute;
    right: 0;
    top: 63.5px;

    .ant-progress-inner {
      background: #dddddd !important;
    }
  }

  .containerSidebar {
    background: #f9fcfe;
    border-right: 1px solid #ddd;
    bottom: 0;
    left: 0;
    min-height: calc(100vh - 77px);
    overflow-y: auto;
    position: absolute;
    top: 77px;
    width: 215px;

    .backToQuestionnaires {
      align-items: center;
      border-bottom: 1px solid #ddd;
      display: flex;
      height: 78px;
      justify-content: space-between;
      padding: 0 15px;

      a {
        color: #000;
        font-size: 16px;
        line-height: 1.25;
      }
    }

    .showSidebar {
      align-items: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      display: flex;
      height: 78px;
      justify-content: space-between;
      padding: 0 15px;

      span {
        color: #000;
        font-size: 16px;
        line-height: 1.25;
      }
    }

    .containerLists {
      overflow: auto;

      .containerListLink {
        border-bottom: 1px solid #ddd;
        display: block;
        margin: 0 18px 0 16px;
        padding: 27px 0px 32px;

        .containerListLink{
          border: none;
          padding: 0;
          margin: 0;
        }

        .listLink {
          color: #134176;
          font-size: 12px;
          margin-left: 5px;

          a {
            color: #134176;
            display: block;
            margin-bottom: 9px;
            text-decoration: underline;
            word-wrap: break-word;
          }

          .activeLink {
            color: #d92612;
            text-decoration: none;
          }
        }

        .titleLink {
          color: #000;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .modalHeader {
    align-items: center;
    background: white;
    display: flex;
    height: 100%;
    justify-content: space-between;
    min-height: (70px + 2.5px);
    width: 100vw;

    .contentHeader {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;

      .containerButton {
        align-items: center;
        display: flex;

        button {
          border: solid 1px #134176;
          border-radius: 21px;
          height: 35px;
          margin-right: 10px;
          padding: 0 22px;
          width: 100%;

          &:hover {
            opacity: 0.4;
          }

          span {
            color: #21416f;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .modalTitle {
        color: #000000;
        font-size: 30px;
        height: 39px;
        line-height: 1.3;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (min-width: 1024px) {
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1024px) {
        justify-content: space-between;
      }
    }

    .logo-box {
      height: (70px + 2.5px);
      width: 215px;     

      .logo-lg img {
        height: (70px + 2.5px);
        object-fit: contain;
        object-position: center;
        width: 215px;
      }
    }
  }
}

.containerLinkCollapse {
  border-top: 1px solid #ddd;
  margin-top: -1px;
}

.containerSpin {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 77px;
}

.ant-tag.ant-tag-checkable {
  cursor: pointer;

  &:hover {
    background-color: #dadada;
  }
}

.ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
  background-color: #ebedf1;
  cursor: pointer;

  &:after {
    content: "X";
    cursor: pointer;
    margin-left: 10px;
  }

  &:hover {
    background-color: #dadada;
  }
}
