.user__block {
  position: relative;
  padding-top: 46px;
  padding-bottom: 60px;
  padding-right: 100px;

  .feedback {
    padding: 7px 25px;
    border: 1px solid #134176;
    border-bottom: none;
    position: fixed;
    font-size: 16px;
    line-height: 20px;
    color: #134176;
    top: 50%;
    left: 100%;
    transform: translateX(-64%) rotate(-90deg);
    cursor: pointer;
  }

  .user__actions {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user__edit-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 18px;
    color: #21416F;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 14px;
      width: 38px;
      height: 38px;
      background: #134176;
      border-radius: 50%;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }
  }


  .user__create-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 18px;
    color: #21416F;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }

    span {
      position: relative;
      margin-left: 14px;
      width: 38px;
      height: 38px;
      background: #134176;
      border-radius: 50%;

      &:after {
        content: '';
        width: 20px;
        height: 2px;
        background: #fff;
        display: block;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }

      &:before {
        content: '';
        width: 20px;
        height: 2px;
        background: #fff;
        display: block;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(90deg);
      }
    }
  }

  .user__list {
    padding: 0 0 32px;
    border: 1px solid #D8D8D8;
    list-style: none;

    &:first-child {
      padding-top: 8px;
    }

    .user__list-item {
      padding: 0 26px 0 12px;
      transition: .3s all;

      &:hover {
        background: #eeeeb3;
      }
    }

    .pagination {
      padding: 32px 26px 0 12px;
    }
  }

  .help-center {
    width: 70%;
  }

  .user__filters {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter__sort {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        margin: 0 5px 0 0;
        font-size: 14px;
        line-height: 18px;
        color: #21416F;
      }

      span {
        color: #21416F;
      }
    }

    .filter__search {
      width: 30%;

      input {
        color: #333333;

        &:focus, &:hover, &:active {
          border-color: #134176;
          box-shadow: none;
        }

        &::placeholder {
          font-size: 14px;
          line-height: 22px;
        }
      }

      button {
        background: #134176;
        border: 1px solid #134176;
      }
    }
  }
}

.button__apply {
  display: block;
  padding: 8px 26px;
  margin: 15px auto 0;
  height: auto;
  background: #1E3968;
  border: 1px solid #134176;
  outline: none;
  border-radius: 18px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition: .3s all;

  &:hover {
    opacity: .5;
  }
}