.no-records {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.no-records img {
  width: 60px;
  height: 60px;
}
.no-records p {
  margin: 10px 0 0;
}