.elementUpdater {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  bottom: 2rem;
  display: flex;
  gap: 2rem;
  padding: 20px;
  position: absolute;
  right: 2rem;
  z-index: 1100;
}
.elementUpdater > span {
  font-weight: 600;
}

.titleQuestionnaire {
  align-items: center;
  display: flex;
  height: 102px;
  justify-content: space-between;
  margin: 23.5px 0 26.5px 0;
}
.titleQuestionnaire .titleQuestionnaire_left .titel {
  color: #000;
  font-size: 48px;
  font-weight: 400;
  line-height: 1.29;
}
.titleQuestionnaire .titleQuestionnaire_right {
  align-items: center;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 102px;
  justify-content: space-evenly;
  padding: 0 10px;
  width: 235px;
}
.titleQuestionnaire .titleQuestionnaire_right .ant-btn.ant-btn-link {
  height: 100%;
}
.titleQuestionnaire .titleQuestionnaire_right button {
  margin: 0 auto;
  display: block;
}
.titleQuestionnaire .titleQuestionnaire_right div {
  width: 100%;
}

.qs-details-shown {
  right: 0 !important;
}
@media (max-width: 1191px) {
  .qs-details-shown {
    display: block !important;
  }
}

.showing-details {
  padding-right: 335px;
  transition: 0.3s;
}
@media (max-width: 1191px) {
  .showing-details {
    padding-right: 0;
  }
}

.qs-details {
  background-color: #fff;
  border-left: 1px solid #D9D9D9;
  bottom: 0;
  overflow-y: auto;
  padding: 0 20px;
  position: fixed;
  right: -100%;
  top: 77px;
  transition: 0.3s;
  width: 300px;
  z-index: 1050;
}
.qs-details-label {
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.qs-details-comment {
  margin-bottom: 20px;
}
.qs-details-comment .ant-comment {
  margin-bottom: 10px;
}
.qs-details-comment .ant-comment .ant-comment-inner {
  padding: 0;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-avatar {
  display: none;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content {
  padding: 10px;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author {
  display: block;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name {
  color: #000;
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-time {
  color: #000;
  font-size: 14px;
  font-style: italic;
}
.qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-detail {
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
}
.qs-details-view-logs {
  list-style: none;
  margin-top: 20px;
  padding: 0;
}
@media (max-width: 1191px) {
  .qs-details {
    border: 1px solid #D9D9D9;
    display: none;
    margin-top: 20px;
    padding: 20px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.qs-details .iconClose {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
}
.qs-details .containerDetails {
  padding-top: 42px;
}
.qs-details .containerDetails .containerButton {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
}
.qs-details .containerDetails .containerButton button {
  background-color: #21416f;
  border: solid 1px #21416f;
  border-radius: 21px;
  color: #fff;
  display: block;
  font-size: 14px;
  height: 35px;
}
.qs-details .containerDetails .title {
  color: #000;
  line-height: 1.33;
  margin-bottom: 30px;
}
.qs-details .containerDetails .detail_textArea {
  border: 1px solid #7a8295;
  border-radius: 0px;
  resize: none;
}

.containerModalTest {
  background: white;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.containerModalTest .containerContent {
  background: white;
  bottom: 0;
  left: 215px;
  min-height: calc(100vh - 77px);
  overflow-y: auto;
  position: absolute;
  top: 77px;
  width: calc(100vw - 215px);
}
.containerModalTest .containerContent .containerFillter {
  border-bottom: 1px solid #ddd;
  display: flex;
  height: 77px;
  justify-content: space-between;
  padding: 0 325px 0 110px;
}
.containerModalTest .containerContent .containerFillter .rightContainer {
  align-items: center;
  display: flex;
}
.containerModalTest .containerContent .containerFillter .leftContainer {
  align-items: center;
  display: flex;
}
.containerModalTest .containerContent .containerFillter .leftContainer .title {
  color: #000;
  font-size: 16px;
  line-height: 1.25;
  margin-right: 10px;
}
.containerModalTest .containerContent .containerMainQuestionnaire {
  padding: 0 110px;
  width: 100%;
}
.containerModalTest .containerProgressbar {
  left: 0;
  position: absolute;
  right: 0;
  top: 63.5px;
}
.containerModalTest .containerProgressbar .ant-progress-inner {
  background: #dddddd !important;
}
.containerModalTest .containerSidebar {
  background: #f9fcfe;
  border-right: 1px solid #ddd;
  bottom: 0;
  left: 0;
  min-height: calc(100vh - 77px);
  overflow-y: auto;
  position: absolute;
  top: 77px;
  width: 215px;
}
.containerModalTest .containerSidebar .backToQuestionnaires {
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  height: 78px;
  justify-content: space-between;
  padding: 0 15px;
}
.containerModalTest .containerSidebar .backToQuestionnaires a {
  color: #000;
  font-size: 16px;
  line-height: 1.25;
}
.containerModalTest .containerSidebar .showSidebar {
  align-items: center;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  height: 78px;
  justify-content: space-between;
  padding: 0 15px;
}
.containerModalTest .containerSidebar .showSidebar span {
  color: #000;
  font-size: 16px;
  line-height: 1.25;
}
.containerModalTest .containerSidebar .containerLists {
  overflow: auto;
}
.containerModalTest .containerSidebar .containerLists .containerListLink {
  border-bottom: 1px solid #ddd;
  display: block;
  margin: 0 18px 0 16px;
  padding: 27px 0px 32px;
}
.containerModalTest .containerSidebar .containerLists .containerListLink .containerListLink {
  border: none;
  padding: 0;
  margin: 0;
}
.containerModalTest .containerSidebar .containerLists .containerListLink .listLink {
  color: #134176;
  font-size: 12px;
  margin-left: 5px;
}
.containerModalTest .containerSidebar .containerLists .containerListLink .listLink a {
  color: #134176;
  display: block;
  margin-bottom: 9px;
  text-decoration: underline;
  word-wrap: break-word;
}
.containerModalTest .containerSidebar .containerLists .containerListLink .listLink .activeLink {
  color: #d92612;
  text-decoration: none;
}
.containerModalTest .containerSidebar .containerLists .containerListLink .titleLink {
  color: #000;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
}
.containerModalTest .modalHeader {
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 72.5px;
  width: 100vw;
}
.containerModalTest .modalHeader .contentHeader {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.containerModalTest .modalHeader .contentHeader .containerButton {
  align-items: center;
  display: flex;
}
.containerModalTest .modalHeader .contentHeader .containerButton button {
  border: solid 1px #134176;
  border-radius: 21px;
  height: 35px;
  margin-right: 10px;
  padding: 0 22px;
  width: 100%;
}
.containerModalTest .modalHeader .contentHeader .containerButton button:hover {
  opacity: 0.4;
}
.containerModalTest .modalHeader .contentHeader .containerButton button span {
  color: #21416f;
  font-size: 14px;
  line-height: 18px;
}
.containerModalTest .modalHeader .contentHeader .modalTitle {
  color: #000000;
  font-size: 30px;
  height: 39px;
  line-height: 1.3;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (min-width: 1024px) {
  .containerModalTest .modalHeader .contentHeader .modalTitle {
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  .containerModalTest .modalHeader .contentHeader {
    justify-content: space-between;
  }
}
.containerModalTest .modalHeader .logo-box {
  height: 72.5px;
  width: 215px;
}
.containerModalTest .modalHeader .logo-box .logo-lg img {
  height: 72.5px;
  object-fit: contain;
  object-position: center;
  width: 215px;
}

.containerLinkCollapse {
  border-top: 1px solid #ddd;
  margin-top: -1px;
}

.containerSpin {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 77px;
}

.ant-tag.ant-tag-checkable {
  cursor: pointer;
}
.ant-tag.ant-tag-checkable:hover {
  background-color: #dadada;
}

.ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
  background-color: #ebedf1;
  cursor: pointer;
}
.ant-tag.ant-tag-checkable.ant-tag-checkable-checked:after {
  content: "X";
  cursor: pointer;
  margin-left: 10px;
}
.ant-tag.ant-tag-checkable.ant-tag-checkable-checked:hover {
  background-color: #dadada;
}