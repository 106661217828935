.custom__header {
  display: flex;
  align-items: center;
  padding: 10px;
  max-height: 70px;
  min-height: 70px;
  color: #21416f;
  background-color: #f3f3f3;
}
.custom__header p {
  margin: 0;
}