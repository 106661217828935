body {
  font-family: "IBM Plex Sans" !important;
}

.ant-popover {
  z-index: 9999 !important;
}

.tox-fullscreen.tox.tox-tinymce.tox-fullscreen {
  padding-top: 70px;
}

.ant-message {
  z-index: 9999 !important;
}

.qs-area .ant-legacy-form-item-children-icon {
  display: none !important;
}

.ant-form .qs-area legend {
  color: inherit;
  border-bottom: 0 !important;
  margin-bottom: 0.5rem !important;
}
.ant-form .qs-area legend.required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff0000;
  font-size: 14px;
  line-height: 1;
  content: "*";
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-form-explain {
  word-break: break-word;
}

.dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-menu {
  background-color: #f3f3f3 !important;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-right: none !important;
}
.ant-menu .ant-menu-sub {
  box-shadow: none !important;
  background-color: #38414a !important;
}
.ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #21416f;
  font-size: 16px !important;
}
.ant-menu .ant-menu-item-selected {
  background: white !important;
  color: black !important;
  font-weight: 900;
  border-radius: 30px !important;
}
.ant-menu .ant-menu-item-selected::after {
  display: none;
}

.ant-comment {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
}
.ant-comment .ant-comment-inner {
  padding: 16px 0 0;
}
.ant-comment .ant-comment-inner .ant-comment-actions {
  margin: -10px 0 0;
}

.ant-collapse-content-box {
  padding: 10px;
}

.containerQuestionnaire .ant-card.ant-card-bordered.ant-card-hoverable :hover {
  background: #fffedf !important;
}

.ant-btn.ant-btn-default.ant-btn-round {
  color: #21416f;
  border-color: #21416f;
}

.containerQuestionnaire .ant-card.ant-card-bordered.ant-card-hoverable :hover {
  background: none;
}

.ant-progress .ant-progress-bg {
  background-color: green;
}

.ant-table.ant-table-default.ant-table-without-column-header.ant-table-scroll-position-left .ant-table-tbody tr {
  height: 77.5px;
}
.ant-table.ant-table-default.ant-table-without-column-header.ant-table-scroll-position-left .ant-table-tbody tr :hover td {
  background: #21416f !important;
}

.ant-pagination.ant-table-pagination {
  margin: 38px 0 !important;
}

.ant-pagination {
  float: left !important;
}
.ant-pagination .ant-pagination-item-active {
  background-color: rgba(19, 65, 118, 0.05) !important;
}
.ant-pagination .ant-pagination-item {
  font-size: 16px;
  border-color: #21416f;
  font-family: "IBM Plex Sans" !important;
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  border-color: #21416f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination .ant-pagination-prev i {
  vertical-align: 0;
  color: #21416f;
  font-weight: 600;
}
.ant-pagination .ant-pagination-prev svg {
  font-weight: 900;
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-color: #21416f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination .ant-pagination-next i {
  vertical-align: 0;
  color: #21416f;
}
.ant-pagination .ant-pagination-next svg {
  font-weight: 900;
}

.containerHelpCenter {
  display: flex;
  width: 100%;
  max-width: 745px;
  height: 146px;
  border: 1px solid #dddddd;
  padding: 0px 35.5px 0 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 43px;
}
.containerHelpCenter .containerHelpCenter_text span {
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
}
.containerHelpCenter .containerHelpCenter_text button {
  border: solid 1px #134176;
}
.containerHelpCenter .containerHelpCenter_text button span {
  font-size: 14px;
  color: #21416f;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fffedf !important;
}

.ant-table-tbody > tr > td {
  padding: 20px 10px !important;
}

.ant-tag-checkable {
  text-align: center;
  width: auto;
  height: 29px !important;
  border-radius: 5px !important;
  border: solid 1px #21416f !important;
  color: #21416f !important;
  font-size: 14px;
  line-height: 14px !important;
  padding: 7px !important;
  font-family: "Open Sans";
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}

.ant-progress-text {
  color: #333333 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.ant-progress-outer {
  font-size: 14px !important;
}

.ant-select-selection--single {
  border-radius: 5px;
  border-color: #a0a7b7 !important;
  color: #134176;
  font-family: "Open Sans";
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 30px !important;
}
.ant-select-selection--single svg {
  color: #134176;
}

div.ant-input-number {
  width: 50%;
}