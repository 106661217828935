@import "../../styles/colours";

.qs-container {
  &.showing-details {
    padding-right: 335px;
    transition: 0.3s;

    .qs-details {
      right: 0;
      top: 77px;

      @media (max-width: 1191px) {
        display: block;
      }
    }

    @media (max-width: 1191px) {
      padding-right: 0;
    }
  }

  .ant-collapse {
    background: white;
    .ant-collapse {
      .ant-collapse-content.ant-collapse-content-active {
        background: #f9fcfe !important;
      }
    }
  }

  .ant-collapse-header {
    border: none !important;
    height: 100% !important;
  }

  .qs-item {
    margin-bottom: 19px;

    .ant-collapse-item.ant-collapse-item-active {
      border: solid 1px #7a8295;
      padding: 0 !important;
      background-color: white;

      .ant-collapse-content.ant-collapse-content-active {
        margin: 0 20px;
        // background: #f9fcfe !important;

        .ant-collapse-item.ant-collapse-item-active {
          border-radius: 2px;
          border-color: #d8d8d8 !important;
          background: #f9fcfe !important;

          .ant-collapse-header {
            background: none;
          }
        }
      }

      .ant-collapse.ant-collapse-icon-position-left {
        .ant-collapse-item {
          border-radius: 25px;
        }
      }

      .ant-collapse-header {
        border: none !important;
        background-color: white;
        margin-top: 1px;
      }

      .ant-collapse-content-box {
        padding: 0px !important;

        .qs-item {
          margin-bottom: 0px;

          .ant-collapse {
            border: none !important;
          }
        }
      }
    }

    .ant-collapse-item .ant-collapse-header {
      height: 73px;
      // padding: 26px 15px 26px 41px;
      border-radius: 2px !important;
      border-color: #d8d8d8 !important;
      background-color: #f9fcfe;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .ant-progress {
    &-inner {
      background-color: $color-gray;
    }

    &-text {
      font-weight: 600;
    }
  }

  .qs-loading-overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qs-html-area {
    margin-bottom: 15px;
  }

  .qs-details {
    width: 300px;
    background-color: #fff;
    border-left: 1px solid $color-gray;
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    padding: 0 20px;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 1050;

    &-label {
      color: #000;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-comment {
      margin-bottom: 20px;

      .ant-comment {
        margin-bottom: 10px;

        .ant-comment-inner {
          padding: 0;

          .ant-comment-avatar {
            display: none;
          }

          .ant-comment-content {
            padding: 10px;

            .ant-comment-content-author {
              display: block;
              &-name {
                display: block;
                font-size: 16px;
                color: #000;
                font-weight: bold;
              }

              &-time {
                font-size: 14px;
                font-style: italic;
                color: #000;
              }
            }

            .ant-comment-content-detail {
              margin-bottom: 20px;
              font-size: 14px;
              color: #000;
            }
          }
        }
      }
    }

    &-view-logs {
      list-style: none;
      padding: 0;
      margin-top: 20px;
    }

    @media (max-width: 1191px) {
      width: 100%;
      display: none;
      border: 1px solid $color-gray;
      margin-top: 20px;
      padding: 20px;
      position: relative;
      top: 0;
      right: 0;
    }

    .iconClose {
      position: absolute;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }

    .contanerDetails {
      padding-top: 42px;

      .containerButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;

        button {
          display: block;
          height: 35px;
          border-radius: 21px;
          border: solid 1px #21416f;
          background-color: #21416f;
          color: #fff;
          font-size: 14px;
        }
      }

      .title {
        font-size: 18px;
        line-height: 1.33;
        color: #000000;
        margin-bottom: 30px;
      }

      .detail_textArea {
        border: 1px solid #7a8295;
        border-radius: 0px;
        resize: none;
      }
    }
  }
}

.titleQuestionnaire {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 102px;
  margin: 23.5px 0 26.5px 0;

  .titleQuestionnaire_left {
    .titel {
      font-size: 48px;
      line-height: 1.29;
      color: #000000;
    }
  }

  .titleQuestionnaire_right {
    display: flex;
    align-items: center;
    width: 235px;
    height: 102px;
    border-radius: 5px;
    border: solid 1px #dddddd;
    background-color: #ffffff;

    div {
      width: 100%;
    }

    .ant-btn.ant-btn-link {
      height: 100%;
    }

    button {
      margin: 0 auto;
      display: block;
    }
  }
}

.Line {
  background: #ddd;
  height: 1px;
  width: 100%;
}

.containerFillter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}

// .ant-collapse-extra {
//   position: relative;
//   width: 80%;
//   float: right;
// }
.collapseHeader {
  display: block;
  width: 100%;
}
.collapseHeaderMain {
  display: flex;
  align-items: center;
  width: 100%;

  .title {
    width: 300px;
    margin-right: 30px;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #21416f;
  }

  .contentСollapseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #333;
    font-size: 14px;
    font-style: italic;
  }
}
.collapseHeaderSelect {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .containerDropdown {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    align-items: center;
    font-family: "Open Sans";
    font-style: italic;
    color: #333333;
    cursor: pointer;
    img {
      height: 15px;
    }
  }
  .containerCheckbox {
    position: absolute;
    width: 300px;
    height: 280px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #dddddd;
    background-color: #ffffff;
    z-index: 900;
    margin: 30px -15px 0 0;

    .helpElement {
      width: 10px;
      height: 10px;
      background: #fff;
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      margin-top: -5px;
      border-top: solid 1px #dddddd;
      border-left: solid 1px #dddddd;
    }

    &-contant {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      padding: 15px;

      .title {
        font-size: 18px;
        color: #000000;
        margin-bottom: 14px;
        max-width: 90%;
        position: relative;
        span {
          font-size: 14px;
          font-style: italic;
          color: #333333;
        }

        .anticon {
          position: absolute;
          z-index: 9999;
          top: 50%;
          right: -20px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.qs-filter {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 6px;
  }
}

.containerLinkCollapse {
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  height: 100%;
  padding: 26px 11px 26px 12px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  .title {
    width: 300px;
    margin-right: 6%;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.28;
    color: #21416f;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.buttonBurger {
  position: absolute;
  left: 0;
  top: 77px;
  width: 30px;
  height: 77px;
  background: #f9fcfe;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
}

.containerMainQuestionnaire {
  .organization__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .titleBlock {
    width: 100%;
    height: 100%;
    background: #134176;
    border-radius: 5px;
    padding: 19px;

    .content {
      font-size: 14px;
      color: white;
      line-height: 18px;

      p {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}

.containerRadioButton {
  .ant-radio-wrapper.ant-radio-wrapper-checked {
    .ant-radio-inner {
      background: #134176 !important;
      border: solid 2px #134176;
      &::after {
        background-color: white !important;
        width: 10px;
        height: 10px;
        left: 2px;
        top: 2px;
      }
    }
  }

  .ant-radio-wrapper {
    margin: 15px 0;
    font-size: 14px;
    line-height: 1.29;
    color: #000000;

    input {
      width: 18px;
      height: 18px;
    }

    .ant-radio-inner {
      width: 18px !important;
      height: 18px !important;
      border: solid 2px #a0a7b7;
      background-color: #ffffff;
    }
  }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #134176 !important;
}

.questionnaireTextArea {
  width: 315px !important;
  height: 79px !important;
  border-radius: 3px !important;
  border: solid 2px #a0a7b7 !important;
  resize: none;
}

.questionnaireInput {
  width: 194px !important;
  height: 40px !important;
  border-radius: 3px !important;
  border: solid 2px #a0a7b7 !important;
  font-size: 16px !important;

  &:hover {
    border-color: #7a8295 !important;
  }

  &:focus {
    border: solid 2px #7a8295 !important;
    background-color: #ebedf1 !important;
    box-shadow: none !important;
  }
}

.questionnaireCheckboxes {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 15px;

    .ant-checkbox {
      .ant-checkbox-inner {
        border: solid 2px #a0a7b7 !important;
        width: 18px;
        height: 18px;

        &:hover {
          border: solid 2px #525566;
        }
      }
    }
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox .ant-checkbox-inner {
      border: solid 1px #134176 !important;
      background-color: #134176;
    }
  }
}

.tag {

  &--disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}
