.topbar__menu {
  min-width: 400px;
  max-height: 700px;
  overflow: scroll;
}
.topbar__menu .menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbar__menu .menu__item p {
  margin: 0;
}
.topbar__menu .menu__info {
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
  transition: 0.3s all;
}
.topbar__menu .menu__info svg {
  transition: 0.3s all;
}
.topbar__menu .menu__info:hover svg {
  font-size: 18px;
}