.report-pages {
  width: 100%;
  padding-bottom: 40px;
}

.reports {
  width: 100%;
}
.reports .header {
  margin-bottom: 24px;
}
.reports .step {
  padding-top: 22px;
}
.reports .report-form {
  margin-top: 14px;
  margin-bottom: 15px;
}
.reports .report-form .ant-select-selector.ant-select-selector {
  height: 100%;
  padding-left: 0;
}
.reports .report-form .ant-select .ant-select-selection-item {
  color: #134176;
  font-size: 36px;
  line-height: 47px;
}
.reports .report-form .ant-select-arrow {
  font-size: 16px;
  color: #21416F;
}
.reports .report__filters {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #DDDDDD;
}
.reports .report__filters-selects {
  display: flex;
}
.reports .report__filters-selects .report__selects {
  margin-right: 48px;
}
.reports .report__filters-selects .report__selects:last-child {
  margin-right: 0;
}
.reports .report__filters-selects p {
  margin: 0;
  font-style: italic;
  font-size: 14px;
  line-height: 23px;
  color: #7A8295;
}
.reports .report__filters-selects .ant-select-selector.ant-select-selector {
  height: 100%;
  padding-left: 0;
  min-width: 200px;
}
.reports .report__filters-selects .ant-select .ant-select-selection-item {
  color: #134176;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}
.reports .report__filters-selects .ant-select-arrow {
  font-size: 12px;
  color: #21416F;
  top: 50%;
}
.reports .report__filters-inputs {
  display: flex;
  align-items: center;
}
.reports .report__filters-inputs .report__inputs {
  margin-right: 14px;
}
.reports .report__filters-inputs .report__filters--select {
  margin-right: 10px;
}
.reports .report__filters-inputs .custom__input {
  padding: 10px 26px 10px 12px;
}
.reports .report__filters-inputs .custom__input input {
  margin-right: 60px;
  color: #1E3968;
  opacity: 0.5;
  font-size: 14px;
  line-height: 23px;
}
.reports .report__filters-inputs .custom__input input::placeholder {
  color: #1E3968;
  font-size: 14px;
  line-height: 23px;
}
.reports .report__filters-inputs .custom__input svg {
  width: 15px;
  height: 15px;
}
.reports .report__filters-inputs .ant-select .ant-select-selector {
  width: 250px;
  padding: 6px 34px 7px 10px;
  height: 100%;
}
.reports .report__filters-inputs .ant-select .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #134176;
  font-size: 14px;
  line-height: 23px;
}
.reports .report__filters-inputs .ant-select-arrow {
  color: #21416F;
  font-size: 12px;
}
.reports .report__saves {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.reports .report__saves .report__saves--text {
  margin-bottom: 0;
  margin-right: 8px;
  color: #21416F;
  font-size: 12px;
  line-height: 15px;
}
.reports .report__saves svg {
  width: 18px;
  height: 18px;
  color: #21416F;
}

.report__organization {
  padding-top: 24px;
  display: flex;
  align-items: center;
}
.report__organization .report__organization--filters {
  margin-left: auto;
}
.report__organization .report__your-organization {
  margin-right: 24px;
}
.report__organization .report__your-organization .report__organization--popover {
  display: flex;
  align-items: center;
  padding: 11px 12px;
  font-size: 14px;
  line-height: 23px;
  color: #134176;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  cursor: pointer;
}
.report__organization .report__your-organization .report__organization--popover p {
  margin: 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report__organization .report__your-organization .report__organization--popover svg {
  margin-left: 50px;
  color: #21416F;
}
.report__organization .report__your-organization .ant-popover-open {
  border: 2px solid #207BF8;
}
.report__organization .report__your-organization .ant-popover-open svg {
  transform: rotate(180deg);
}
.report__organization .report__your-organization .ant-select-selector.ant-select-selector {
  height: 100%;
  padding-left: 0;
}
.report__organization .report__your-organization .ant-select .ant-select-selector {
  padding: 5px 12px 5px 10px;
  height: 100%;
  border: 2px solid #A0A7B7;
}
.report__organization .report__your-organization .ant-select .ant-select-selector .ant-select-selection-item {
  color: #134176;
  padding-right: 50px;
}
.report__organization .report__your-organization .ant-select .ant-select-selection-item {
  color: #134176;
  font-weight: normal;
  font-size: 14px;
}
.report__organization .report__your-organization .ant-select-arrow {
  font-size: 12px;
  color: #21416F;
  top: 50%;
}
.report__organization .report__organization-text {
  margin-right: 20px;
  font-size: 14px;
  line-height: 23px;
  font-style: italic;
  color: #7A8295;
}
.report__organization .report__apply {
  display: block;
  margin-left: auto;
  margin-right: 30px;
  padding: 9px 20px;
  background: #21416F;
  border: 1px solid #21416F;
  color: #fff;
  transition: 0.3s all;
}
.report__organization .report__apply:hover {
  opacity: 0.4;
}
.report__organization .report__apply:disabled {
  opacity: 0.2;
  cursor: no-drop;
}
.report__organization .filter-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: #21416F;
  cursor: pointer;
  transition: 0.3s all;
}
.report__organization .filter-text:hover {
  opacity: 0.4;
}
.report__organization .filter-text svg {
  color: #134176;
  margin-left: 6px;
}
.report__organization .filter-content {
  display: flex;
  align-items: center;
}
.report__organization .filter-content .filter-popover {
  margin-left: 28px;
}
.report__organization .filter-content .filter-popover img {
  margin-left: 6px;
}

.report__search-filter {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.report__search-filter p {
  margin: 0 10px 0 0;
  font-size: 18px;
  line-height: 22px;
  color: #21416F;
}
.report__search-filter span {
  cursor: pointer;
  color: #21416F;
}

.report__tabs {
  margin-top: 46px;
}
.report__tabs .download__benchmark {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-bottom: 30px;
  background: #134176;
  border-color: #134176;
  border-radius: 10px;
}
.report__tabs .ant-collapse-content-box.ant-collapse-content-box {
  padding: 0;
}
.report__tabs .tabs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report__tabs .tabs__header .columns {
  width: 20%;
  max-width: 170px;
  padding-left: 12px;
  padding-bottom: 22px;
  border-left: 1px solid #D8D8D8;
  color: #000000;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report__tabs .tabs__header .median-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report__tabs .tabs__header .median-block .table-img {
  width: 24px;
  height: 20px;
  color: #1E3968;
  cursor: pointer;
}
.report__tabs .report__item {
  margin-bottom: 6px;
  position: relative;
}
.report__tabs .all-report__item .ant-collapse-header {
  background: #ededed;
}
.report__tabs .ant-collapse-header {
  background: #DDE0E6;
  color: #000000 !important;
  font-size: 18px;
  line-height: 23px;
}
.report__tabs .tab__title {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 12px;
  left: 12px;
  right: 12px;
}
.report__tabs .tab__title-text {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.report__tabs .tab__title-content {
  display: flex;
  align-items: center;
  color: #21416F;
  font-size: 14px;
  line-height: 23px;
}
.report__tabs .map, .report__tabs .chart {
  margin-right: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
}
.report__tabs .map .map__img, .report__tabs .chart .map__img {
  margin-right: 4px;
}
.report__tabs .map:hover, .report__tabs .chart:hover {
  opacity: 0.5;
}
.report__tabs .info {
  display: flex;
  align-items: center;
  color: #7A8295;
  font-size: 12px;
  line-height: 20px;
}
.report__tabs .info svg {
  margin-left: 4px;
}
.report__tabs .favorite {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
}
.report__tabs .favorite svg {
  margin-left: 4px;
}
.report__tabs .favorite:hover {
  color: #fadb14;
  cursor: pointer;
}
.report__tabs .favorite.checked {
  color: #fadb14;
}
.report__tabs .report__content {
  padding-top: 47px;
  padding-left: 12px;
  padding-bottom: 28px;
  width: 20%;
  border-left: 1px solid #D8D8D8;
  color: #000000;
  font-size: 18px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
}
.report__tabs .report__content:first-child {
  border-left: none;
}
.report__tabs .report__content-block {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report__tabs .report__content-block:nth-child(even) {
  background: #edf0f1;
}
.report__tabs .ant-collapse-content-box {
  min-height: 50px;
  padding: 0;
}

.help-center {
  width: 100%;
  margin-top: 40px;
  padding: 26px 26px 26px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DDDDDD;
}
.help-center .help-center__title {
  margin-bottom: 12px;
  color: #000000;
  font-size: 24px;
  line-height: 32px;
}
.help-center .help-center__action {
  padding: 9px 18px;
  border: 1px solid #134176;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  background: transparent;
  border-radius: 18px;
  transition: 0.3s all;
}
.help-center .help-center__action:hover {
  opacity: 0.4;
}

.ant-modal-content .ant-modal-header {
  padding-bottom: 40px;
  border-bottom: none;
}
.ant-modal-content .ant-modal-body {
  min-height: 85vh;
  padding-bottom: 38px;
}
.ant-modal-content .ant-modal-title {
  font-size: 38px;
  line-height: 49px;
  font-weight: bold;
  color: #000000;
}
.ant-modal-content .ant-modal-title svg {
  color: #164274;
}

.report__modal .ant-modal-content .ant-modal-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}
.report__modal .ant-modal-content .ant-modal-header {
  padding: 20px 20px 4px;
}
.report__modal .ant-modal-content .ant-modal-body {
  padding: 0 20px;
}
.report__modal .ant-modal-close-x svg {
  color: #21416F;
}