.field__item > div {
  height: 100%;
  padding-bottom: 10px;
}

.field__item > div > div {
  height: 100%;
}

.field__item > div > div > table {
  height: 100%;
}

tr, td {
  padding-top: 10px;
  padding-bottom: 10px;
}

iframe {
  margin-top: -10px;
}

article.node--type-basic-page-report-blocks > header {
  display: none;
}
article.node--type-basic-page-report-blocks > h2:is(:first-child) {
  display: none;
}
article.node--type-basic-page-report-blocks > footer.node__meta {
  display: none;
}