.error__page {
  padding-top: 50px;
  max-width: 700px;
  margin: 0 auto;

  .title {
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 2px solid #eeeeee;
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
  }

  .subtitle {
    padding-top: 20px;
    margin: 0 auto;
    max-width: 480px;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
  }

  .return__actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
  }

  .comeback {
    margin: 0 5px;
    font-size: 18px;
    line-height: 26px;
    color: #0b72b5;
    font-weight: bold;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }


  }
}