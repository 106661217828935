.messages {
  display: flex;
  align-items: center;
  span {
    margin-left: 7.6px;
  }
}

.error {
  color: red;
}

.warning {
  color: #bd4800;
}

.noneMessages {
  display: none;
}

.tableTitle {
  max-width: 150px;
}