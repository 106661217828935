@charset "UTF-8";
.qs-container.showing-details {
  padding-right: 335px;
  transition: 0.3s;
}
.qs-container.showing-details .qs-details {
  right: 0;
  top: 77px;
}
@media (max-width: 1191px) {
  .qs-container.showing-details .qs-details {
    display: block;
  }
}
@media (max-width: 1191px) {
  .qs-container.showing-details {
    padding-right: 0;
  }
}
.qs-container .ant-collapse {
  background: white;
}
.qs-container .ant-collapse .ant-collapse .ant-collapse-content.ant-collapse-content-active {
  background: #f9fcfe !important;
}
.qs-container .ant-collapse-header {
  border: none !important;
  height: 100% !important;
}
.qs-container .qs-item {
  margin-bottom: 19px;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active {
  border: solid 1px #7a8295;
  padding: 0 !important;
  background-color: white;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content.ant-collapse-content-active {
  margin: 0 20px;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content.ant-collapse-content-active .ant-collapse-item.ant-collapse-item-active {
  border-radius: 2px;
  border-color: #d8d8d8 !important;
  background: #f9fcfe !important;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content.ant-collapse-content-active .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  background: none;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse.ant-collapse-icon-position-left .ant-collapse-item {
  border-radius: 25px;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  border: none !important;
  background-color: white;
  margin-top: 1px;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content-box {
  padding: 0px !important;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content-box .qs-item {
  margin-bottom: 0px;
}
.qs-container .qs-item .ant-collapse-item.ant-collapse-item-active .ant-collapse-content-box .qs-item .ant-collapse {
  border: none !important;
}
.qs-container .qs-item .ant-collapse-item .ant-collapse-header {
  height: 73px;
  border-radius: 2px !important;
  border-color: #d8d8d8 !important;
  background-color: #f9fcfe;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.qs-container .ant-progress-inner {
  background-color: #D9D9D9;
}
.qs-container .ant-progress-text {
  font-weight: 600;
}
.qs-container .qs-loading-overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qs-container .qs-html-area {
  margin-bottom: 15px;
}
.qs-container .qs-details {
  width: 300px;
  background-color: #fff;
  border-left: 1px solid #D9D9D9;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  padding: 0 20px;
  overflow-y: auto;
  transition: 0.3s;
  z-index: 1050;
}
.qs-container .qs-details-label {
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.qs-container .qs-details-comment {
  margin-bottom: 20px;
}
.qs-container .qs-details-comment .ant-comment {
  margin-bottom: 10px;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner {
  padding: 0;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-avatar {
  display: none;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content {
  padding: 10px;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author {
  display: block;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name {
  display: block;
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-time {
  font-size: 14px;
  font-style: italic;
  color: #000;
}
.qs-container .qs-details-comment .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-detail {
  margin-bottom: 20px;
  font-size: 14px;
  color: #000;
}
.qs-container .qs-details-view-logs {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}
@media (max-width: 1191px) {
  .qs-container .qs-details {
    width: 100%;
    display: none;
    border: 1px solid #D9D9D9;
    margin-top: 20px;
    padding: 20px;
    position: relative;
    top: 0;
    right: 0;
  }
}
.qs-container .qs-details .iconClose {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
.qs-container .qs-details .contanerDetails {
  padding-top: 42px;
}
.qs-container .qs-details .contanerDetails .containerButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.qs-container .qs-details .contanerDetails .containerButton button {
  display: block;
  height: 35px;
  border-radius: 21px;
  border: solid 1px #21416f;
  background-color: #21416f;
  color: #fff;
  font-size: 14px;
}
.qs-container .qs-details .contanerDetails .title {
  font-size: 18px;
  line-height: 1.33;
  color: #000000;
  margin-bottom: 30px;
}
.qs-container .qs-details .contanerDetails .detail_textArea {
  border: 1px solid #7a8295;
  border-radius: 0px;
  resize: none;
}

.titleQuestionnaire {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 102px;
  margin: 23.5px 0 26.5px 0;
}
.titleQuestionnaire .titleQuestionnaire_left .titel {
  font-size: 48px;
  line-height: 1.29;
  color: #000000;
}
.titleQuestionnaire .titleQuestionnaire_right {
  display: flex;
  align-items: center;
  width: 235px;
  height: 102px;
  border-radius: 5px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}
.titleQuestionnaire .titleQuestionnaire_right div {
  width: 100%;
}
.titleQuestionnaire .titleQuestionnaire_right .ant-btn.ant-btn-link {
  height: 100%;
}
.titleQuestionnaire .titleQuestionnaire_right button {
  margin: 0 auto;
  display: block;
}

.Line {
  background: #ddd;
  height: 1px;
  width: 100%;
}

.containerFillter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}

.collapseHeader {
  display: block;
  width: 100%;
}

.collapseHeaderMain {
  display: flex;
  align-items: center;
  width: 100%;
}
.collapseHeaderMain .title {
  width: 300px;
  margin-right: 30px;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #21416f;
}
.collapseHeaderMain .contentСollapseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #333;
  font-size: 14px;
  font-style: italic;
}

.collapseHeaderSelect {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.collapseHeaderSelect .containerDropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;
  font-family: "Open Sans";
  font-style: italic;
  color: #333333;
  cursor: pointer;
}
.collapseHeaderSelect .containerDropdown img {
  height: 15px;
}
.collapseHeaderSelect .containerCheckbox {
  position: absolute;
  width: 300px;
  height: 280px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dddddd;
  background-color: #ffffff;
  z-index: 900;
  margin: 30px -15px 0 0;
}
.collapseHeaderSelect .containerCheckbox .helpElement {
  width: 10px;
  height: 10px;
  background: #fff;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  margin-top: -5px;
  border-top: solid 1px #dddddd;
  border-left: solid 1px #dddddd;
}
.collapseHeaderSelect .containerCheckbox-contant {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  padding: 15px;
}
.collapseHeaderSelect .containerCheckbox-contant .title {
  font-size: 18px;
  color: #000000;
  margin-bottom: 14px;
  max-width: 90%;
  position: relative;
}
.collapseHeaderSelect .containerCheckbox-contant .title span {
  font-size: 14px;
  font-style: italic;
  color: #333333;
}
.collapseHeaderSelect .containerCheckbox-contant .title .anticon {
  position: absolute;
  z-index: 9999;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.qs-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.qs-filter span {
  margin-right: 6px;
}

.containerLinkCollapse {
  height: 100%;
  padding: 26px 11px 26px 12px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.containerLinkCollapse .title {
  width: 300px;
  margin-right: 6%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.28;
  color: #21416f;
}
.containerLinkCollapse .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonBurger {
  position: absolute;
  left: 0;
  top: 77px;
  width: 30px;
  height: 77px;
  background: #f9fcfe;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
}

.containerMainQuestionnaire .organization__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
}
.containerMainQuestionnaire .titleBlock {
  width: 100%;
  height: 100%;
  background: #134176;
  border-radius: 5px;
  padding: 19px;
}
.containerMainQuestionnaire .titleBlock .content {
  font-size: 14px;
  color: white;
  line-height: 18px;
}
.containerMainQuestionnaire .titleBlock .content p {
  font-size: 24px;
  line-height: 32px;
}

.containerRadioButton .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background: #134176 !important;
  border: solid 2px #134176;
}
.containerRadioButton .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
  background-color: white !important;
  width: 10px;
  height: 10px;
  left: 2px;
  top: 2px;
}
.containerRadioButton .ant-radio-wrapper {
  margin: 15px 0;
  font-size: 14px;
  line-height: 1.29;
  color: #000000;
}
.containerRadioButton .ant-radio-wrapper input {
  width: 18px;
  height: 18px;
}
.containerRadioButton .ant-radio-wrapper .ant-radio-inner {
  width: 18px !important;
  height: 18px !important;
  border: solid 2px #a0a7b7;
  background-color: #ffffff;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #134176 !important;
}

.questionnaireTextArea {
  width: 315px !important;
  height: 79px !important;
  border-radius: 3px !important;
  border: solid 2px #a0a7b7 !important;
  resize: none;
}

.questionnaireInput {
  width: 194px !important;
  height: 40px !important;
  border-radius: 3px !important;
  border: solid 2px #a0a7b7 !important;
  font-size: 16px !important;
}
.questionnaireInput:hover {
  border-color: #7a8295 !important;
}
.questionnaireInput:focus {
  border: solid 2px #7a8295 !important;
  background-color: #ebedf1 !important;
  box-shadow: none !important;
}

.questionnaireCheckboxes {
  display: flex;
  flex-direction: column;
}
.questionnaireCheckboxes label {
  margin-bottom: 15px;
}
.questionnaireCheckboxes label .ant-checkbox .ant-checkbox-inner {
  border: solid 2px #a0a7b7 !important;
  width: 18px;
  height: 18px;
}
.questionnaireCheckboxes label .ant-checkbox .ant-checkbox-inner:hover {
  border: solid 2px #525566;
}
.questionnaireCheckboxes .ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner {
  border: solid 1px #134176 !important;
  background-color: #134176;
}

.tag--disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}