.organization-page .files__header {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organization-page .ant-breadcrumb .ant-breadcrumb-link {
  transition: 0.3s all;
  cursor: pointer;
}
.organization-page .ant-breadcrumb .ant-breadcrumb-link:hover {
  opacity: 0.5;
}
.organization-page .rules__block {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
}
.organization-page .rules__block:hover {
  opacity: 0.5;
}
.organization-page .rules__block p {
  margin: 0 10px 0 0;
  font-weight: bold;
  text-transform: uppercase;
}
.organization-page .rules__block svg {
  width: 18px;
  height: 18px;
}