.popover__organization {
  padding: 10px 40px 22px 12px;
}
.popover__organization .organization__action {
  margin-top: 25px;
}
.popover__organization .radio__groups {
  display: flex;
}
.popover__organization .radio__group:first-child {
  margin-right: 72px;
}
.popover__organization .radio__group .radio__title {
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 23px;
}
.popover__organization .radio__group .ant-radio-group {
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-wrapper {
  margin-bottom: 5px;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-checked .ant-radio-inner {
  background: #1E3968;
  border-color: #1E3968;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-checked .ant-radio-inner::after {
  content: "";
  background-color: #fff;
}

.popover__group {
  padding: 10px 40px 22px 12px;
}
.popover__group .group__block {
  display: flex;
}
.popover__group .group__radio--item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #D8D8D8;
}
.popover__group .group__item:first-child {
  border-right: 1px solid #D8D8D8;
  padding-right: 50px;
}
.popover__group .group__item:last-child {
  padding-left: 50px;
}
.popover__group .group__item .group__title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
}
.popover__group .group__item .group__icons {
  display: flex;
  align-items: center;
}
.popover__group .group__item .ant-radio-group {
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper {
  display: flex;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper > span:not(.ant-radio) {
  display: block;
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper span {
  font-weight: 500;
  line-height: 18px;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper span svg {
  color: #1E3968;
}
.popover__group .group__item .ant-radio-group .anticon-edit {
  width: 16px;
  height: 16px;
  transition: 0.4s all;
}
.popover__group .group__item .ant-radio-group .anticon-edit svg {
  width: 16px;
  height: 16px;
  fill: #1E3968;
}
.popover__group .group__item .ant-radio-group .anticon-edit:hover {
  opacity: 0.4;
}
.popover__group .group__item .ant-radio-group .ant-radio-checked .ant-radio-inner {
  background: #1E3968;
  border-color: #1E3968;
}
.popover__group .group__item .ant-radio-group .ant-radio-checked .ant-radio-inner::after {
  content: "";
  background-color: #fff;
}
.popover__group .group__item .delete-group {
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transition: 0.4s all;
}
.popover__group .group__item .delete-group:hover {
  opacity: 0.4;
}
.popover__group .group__item .delete-group:after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}
.popover__group .group__item .delete-group:before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}

.group__action {
  display: flex;
  margin: 50px auto 0;
  padding: 10px 30px;
  border: 1px solid #21416F;
  background: transparent;
  outline: none;
  border-radius: 24px;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  transition: 0.3s all;
}
.group__action:hover {
  background: #21416F;
  color: #fff;
}

.apply__button {
  background: #21416F;
  color: #fff;
}
.apply__button:hover {
  opacity: 0.5;
}

.popover__content .popover__title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.popover__content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.popover__content .ant-checkbox-wrapper span {
  font-size: 14px;
  line-height: 37px;
}
.popover__content .ant-checkbox-group-item {
  margin-right: 0;
}
.popover__content .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.popover__content .ant-checkbox-checked .ant-checkbox-inner {
  background: #134176;
  border: 1px solid #134176;
  border-radius: 3px;
}

.popover__individual--group .group__block {
  max-width: 200px;
  width: 100%;
}
.popover__individual--group .group__title {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.popover__individual--group .group__subtitle {
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 18px;
  font-style: italic;
  color: #000000;
}
.popover__individual--group .group__checkboxes {
  max-height: 200px;
  overflow: auto;
}
.popover__individual--group .group__checkboxes .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.popover__individual--group .group__checkboxes .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.popover__individual--group .group__checkboxes .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  max-width: 200px;
}
.popover__individual--group .group__checkboxes .ant-checkbox-wrapper span {
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover__individual--group .group__checkboxes .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #21416F;
  border-color: #21416F;
}
.popover__individual--group .action__button {
  display: flex;
  margin: 25px auto 0;
  padding: 10px 30px;
  border: 1px solid #21416F;
  outline: none;
  border-radius: 24px;
  font-size: 14px;
  line-height: 18px;
  transition: 0.3s all;
  background: #21416F;
  color: #fff;
}
.popover__individual--group .action__button:hover {
  opacity: 0.5;
}