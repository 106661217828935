@import "../../../../../../styles/colours";

.ant-radio-wrapper-in-form-item {
  display: block !important;
}

.containerRadioButton {
  .ant-radio-wrapper.ant-radio-wrapper-checked {
    .ant-radio-inner {
      background: #134176 !important;
      border: solid 2px #134176;
      
      &::after {
        background-color: white !important;
        height: 10px;
        left: 2px;
        top: 2px;
        width: 10px;
      }
    }
  }

  .ant-radio-wrapper {
    color: #000000;
    font-size: 14px;
    line-height: 1.29;
    margin: 15px 0;

    input {
      height: 18px;
      width: 18px;
    }

    .ant-radio-inner {
      background-color: #ffffff;
      border: solid 2px #a0a7b7;
      height: 18px !important;
      width: 18px !important;
    }
  }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #134176 !important;
}