.reset__password {
  margin-top: 25px;
  padding: 25px 18px;
  border: 1px solid #D8D8D8;
  border-radius: 2px;

  .content {
    max-width: 385px;
  }

  .title {
    margin-bottom: 2px;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .last__change {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 23px;
    color: #333333;
    font-style: italic;
  }

  .label {
    margin: 0;
    font-size: 14px;
    line-height: 23px;
    color: #333333;
  }

  .password__data {
    margin-bottom: 12px;
    padding: 8px 11px;
    height: 40px;
    border: 2px solid #A0A7B7;
    border-radius: 3px;

    &:disabled {
      color: #333333;
    }

    &:focus, &:hover {
      border-color: #A0A7B7;
      box-shadow: none;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
    line-height: 23px;
    color: #333333;
    font-weight: normal;

    .ant-checkbox + span {
      padding-right: 0;
    }


    .ant-checkbox-checked .ant-checkbox-inner {
      background: #1E3968;
      border: 2px solid #1E3968;
    }

    .ant-checkbox-inner {
      border: 2px solid #A0A7B7;
    }
  }

  .action__button {
    padding: 8px 26px;
    background: #1E3968;
    border: 1px solid #134176;
    outline: none;
    border-radius: 18px;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }

    &:disabled {
      background: #eeeeee;
      border: 1px solid #eeeeee;
    }
  }

  .generate__password {
    margin-bottom: 28px;
  }

  .notes {
    width: 450px;
    margin-bottom: 10px;
    margin-top: 14px;
    font-size: 12px;
    line-height: 23px;
    font-weight: bold;
    color: #333333;
  }
}