.ant-collapse.ant-collapse {
  margin-bottom: 10px;
}

.collapse__block {

  .ant-collapse-item {
    background: #F5F6F8;

    .ant-collapse-header {
      padding-right: 16px !important;
    }
  }

  .ant-collapse-arrow {
    top: 30px!important;
  }

  button.ant-collapse-arrow {
    padding: 12px 18px;
    border: 1px solid #21416F;
    background: transparent;
    color: #21416F;
    font-size: 14px;
    line-height: 18px;
    border-radius: 16px;
    transition: .3s all;

    &:hover {
      opacity: .4;
    }
  }
}

.panel__header {

  .titles {
    padding-bottom: 10px;
    border-bottom: 1px solid #979DAD;

    span {
      font-size: 12px;
      line-height: 15px;
      color: #525566;
      font-style: italic;
    }

    .report__title {
      font-size: 18px;
      line-height: 23px;
      color: #1E3968;

      span {
        font-size: 18px;
        line-height: 23px;
        color: #1E3968;
        font-weight: bold;
        font-style: normal;
      }
    }

  }

  .steps {
    padding-top: 10px;
    display: flex;
    align-items: center;

    .icons {
      margin-right: 8px;
      transform: rotate(90deg);
    }

    .step {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .step__title {
        margin: 0 10px 0 0;
        color: #1E3968;
        font-size: 14px;
        line-height: 30px;
      }

      svg {
        width: 12px;
        height: 12px;
        color: #346DF6;
      }

      &:last-child {
        svg {
          display: none;
        }
      }
    }

  }
}
