.file__update .upload {
  margin: 15px 0;
  position: relative;
}
.file__update .upload:hover .file__upload--block {
  font-size: 26px;
}
.file__update .file__input {
  display: block;
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: 300px;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}
.file__update .file__upload--block {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px dashed gray;
  border-radius: 18px;
  cursor: pointer;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  background: #eeeeee;
  transition: 0.3s all;
}
.file__update .action__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file__update .action__block .file__info {
  display: flex;
  align-items: center;
}
.file__update .action__block .file__info p {
  margin: 0 5px 0 0;
}
.file__update .action__block svg {
  transition: 0.3s all;
  cursor: pointer;
}
.file__update .action__block svg:hover {
  font-size: 18px;
  fill: darkred;
}