.modal__view {

  .view__label {
    margin-bottom: 3px;
    font-weight: bold;
  }

  input {
    border: 1px solid #9e9e9e;
    border-radius: 2px;

    &:hover {
      border-color: #21416F;
    }

    &::placeholder {
      font-size: 14px;
    }
  }

  .action__block {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .button {
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    transition: .3s all;
    cursor: pointer;

    &:hover {
      opacity: .4;
    }
  }

  .button__cancel {
    margin-right: 10px;
    border: 1px solid #9c3434;
    background: #9c3434;
  }

  .button__save {
    border: 1px solid #21416F;
    background: #21416F;
  }

}