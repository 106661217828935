.report__filters--select {

  .ant-select-selector.ant-select-selector .ant-select-selection-item {
    font-size: 14px;
    line-height: 23px;
  }
}

.report__saves {
  transition: .3s all;

  &:hover {
    opacity: 0.4;
  }
}

.saved__views--option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin: 0;
  }

  svg {
    cursor: pointer;
    transition: .3s all;

    &:hover {
      fill: darkred;
      transform: scale(1.1);
    }
  }
}