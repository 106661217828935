.account-pages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f9fcfe;

  .containerLogin {
    background: #ffffff;
    width: 320px;
    border: solid 1px #d8d8d8;
    padding: 16px;
    color: #333;

    .buttonLogin {
      width: 100%;
      border-radius: 21px;
      border: solid 1px #21416f;
      background-color: #21416f;
      margin: 0;
    }

    .title {
      font-size: 24px;
      line-height: 1.25;
      color: #000000;
      padding-right: 10px;
      margin: 24px 0;
    }

    .logo {
      width: 146px;
      height: 50px;
      margin-left: -10px;

      img {
        width: 190px;
        height: 50px;
        object-fit: cover;
        object-position: left;
      }
    }

    .linkForgot {
      font-size: 14px;
      line-height: 1.29;
      color: #21416f;
      width: 100%;
      text-align: center;
    }
  }
}
