.file-manager__header {
  padding: 30px 0 20px;
  display: block;
  justify-content: space-between;
  align-items: center;

  .ant-input-group-wrapper {
    width: 25%;
  }

  .select__organization {
    width: 300px;
  }

  .search__block {
    display: block;
    text-align: right;
    margin-bottom: 10px;
  }

  .actions__block {
    display: flex;

    &:first-child {
      margin-right: 10px;
    }
  }

  .form__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .import__file {
    display: flex;
    align-items: center;

    .custom__input {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 4px 15px;
      border: 1px solid #313131;

      span {
        margin-right: 5px;
      }
    }

    input {
      position: absolute;
      opacity: 0;
    }

    .upload__name {
      margin: 0 10px 0 0;
    }

  }

  .download__all {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
}