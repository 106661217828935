.analyse-block {
  width: 100%;
}
.analyse-block .feedback {
  padding: 7px 25px;
  border: 1px solid #134176;
  border-bottom: none;
  position: fixed;
  font-size: 16px;
  line-height: 20px;
  color: #134176;
  top: 50%;
  left: 100%;
  transform: translateX(-77%) rotate(-90deg);
  cursor: pointer;
}

.analyse-list {
  display: block;
  width: 100%;
  padding: 0 80px 0 0;
  list-style: none;
}
.analyse-list .analyse-list__period-title {
  margin: 0;
  color: #7A8295;
  font-size: 14px;
  line-height: 23px;
  font-style: italic;
}
.analyse-list .analyse-list__select {
  color: #134176;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  border: none;
  outline: none;
  border-bottom: 1px solid #DDDDDD;
}
.analyse-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 0;
  height: 20px;
}
.analyse-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 20px;
}
.analyse-list .ant-select-arrow svg {
  color: #134176;
}
.analyse-list .analyse-list__button {
  padding: 9px 22px;
  border: 1px solid #21416F;
  background: #fff;
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
  border-radius: 21px;
  opacity: 1;
  transition: 0.3s all;
}
.analyse-list .analyse-list__button:hover {
  opacity: 0.4;
}