@import "../../styles/colours";

// .benchmark {
//   height: 100%;
//   display: flex;
//   flex-direction: column;

//   &__spinner-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
//   }

//   &__header-container {
//     display: flex;
//     flex-wrap: wrap;
//     padding: 20px 0;
//   }

//   &__filter {
//     display: flex;
//     flex-direction: column;
//     margin-right: 20px;
//     margin-bottom: 20px;

//     &-title {
//       text-transform: uppercase;
//       font-size: 10px;
//       margin-bottom: 5px;
//       opacity: .8;
//       font-weight: 600;
//     }
//   }

//   &__body-container {
//     width: 100%;
//     overflow-x: auto;
//   }
// }

.pageBenchmark {
  display: flex;
  width: 100%;
  padding: 100px 0 0 0;

  .mainForm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -100px;
    width: 100%;
    height: calc(100vh - 129px);

    button {
      width: 30%;
      height: 100%;
      max-height: 200px;
      font-size: 28px;

      &:active {
        color: #5273a0;
      }

      &:focus {
        color: #21416f;
        border-color: #21416f;
      }

      &:hover {
        border-color: #21416f;
        color: #21416f;
        font-size: 30px;
      }
    }
  }

  .containerLeft {
    margin-right: 50px;
    max-width: 220px;

    .ItemLeft {
      margin-bottom: 50px;

      .containerButton {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        max-width: 220px;
      }

      .ant-calendar-picker {
        input {
          border-color: #a0a7b7 !important;
          color: #134176;
        }
      }
    }
  }

  .containerRight {
    width: 100%;
    // text-align: center;

    .containerSearch {
      .ant-input-search.ant-input-search-enter-button.ant-input-search-large.ant-input-group-wrapper.ant-input-group-wrapper-lg {
        .ant-input-wrapper.ant-input-group {
          .ant-input {
            height: 35px !important;

            &:hover {
              border-color: #21416f !important;
            }

            &:focus {
              box-shadow: 0 0 3px #21416f;
              border-color: #21416f !important;
            }
          }
          .ant-input-group-addon {
            button {
              background: #21416f;
              border-color: #21416f;
              height: 35px !important;
            }
          }
        }
      }
    }

    .questiannaire {
      font-size: 18px;
      margin-top: 40px;
    }

    .questian {
      margin-top: 30px;
      font-size: 24px;
    }

    .containerButton {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 50px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #21416f;
        width: 240px;
        height: 120px;
        padding: 40px;
        transition: 0.5s;
        font-size: 30px;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 10px #21416f;
        }
      }
    }

    .path {
      text-align: left;
      margin: 50px 0;
    }

    .categories {
      .ant-pagination {
        float: none !important
      }
    }

    .customFormulars {
      .ant-input {
        height: 40px;
        margin-bottom: 20px;

        &:hover {
          border-color: #21416f !important;
        }

        &:focus {
          box-shadow: 0 0 2px #21416f;
          border-color: #21416f !important;
        }
      }
    }

    .customFormulars, .categoriesItem {
      .title {
        font-size: 20px;
        font-weight: 900;
      }

      .switches {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;

        .item {
          border: 2px solid #21416f;
          padding: 5px;
          margin-left: 10px;
        }
      }
    }
  }
}
