.arrow__back {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #134176;
  cursor: pointer;
}
.arrow__back .text {
  margin: 0 0 0 8px;
}