.user__form {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px 25px 18px;
  border: 1px solid #D8D8D8;
  border-radius: 2px;

  .characteristics {
    width: 45%;

    .ant-form {

      .ant-row {
        margin-bottom: 15px!important;

        &:last-child {
          margin-bottom: 0!important;
        }
      }

      .ant-col {
        padding-bottom: 0px;
      }

      label {
        flex-direction: row-reverse;
        font-size: 14px;
        line-height: 23px;
        font-weight: normal;
        color: #333333;

        &:before {
          margin-right: 0;
          margin-left: 4px;
        }
      }

      .ant-form-item-control-input {

        input {
          max-width: 385px;
          width: 100%;
          border: 2px solid #A0A7B7;
          border-radius: 3px;
          outline: none;
          color: #333333;

          &:focus {
            border-color: #A0A7B7;
          }
        }
      }

      .generate__button {
        margin-top: 5px;
        display: block;
        width: 120px;
        cursor: pointer;
      }

      .ant-checkbox-wrapper {
        margin-left: 0;
        font-size: 14px;
        line-height: 23px;
        color: #333333;

        .ant-checkbox-checked .ant-checkbox-inner {
          background: #1E3968;
          border: 2px solid #1E3968;
        }

        .ant-checkbox-inner {
          border: 2px solid #A0A7B7;
        }
      }
    }

    .action__button {
      margin-right: 16px;
      padding: 8px 26px;
      height: auto;
      background: #1E3968;
      border: 1px solid #134176;
      outline: none;
      border-radius: 18px;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      transition: .3s all;

      &:hover {
        opacity: .5;
      }
    }

    .avatar{
      display: flex;
      align-items: center;

      input.avatar__upload {
        position: relative;
        max-width: 51px;
        height: 51px;
        z-index: 10;
        border: none;
        opacity: 0;
      }

      .avatar__icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #F3F3F3;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .avatar__label {
        margin: 0 0 0 14px;
        font-size: 14px;
        line-height: 23px;
        color: #333333;

        svg {
          margin-left: 10px;
          cursor: pointer;
          transition: .3s all;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .cancel__button {
      font-size: 14px;
      line-height: 18px;
      background: transparent;
      color: #C82922;
      border: none;
      outline: none;
      transition: .3s all;

      &:hover {
        opacity: .5;
      }
    }
  }

  .title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .rights {
    position: relative;

    .roles {
      margin-bottom: 50px;
    }

    .organizations {
      margin-bottom: 50px;
    }

    .select__block {
      padding: 20px 20px 35px 20px;
      background: #DDE0E6;
    }

    .ant-select {
      max-width: 288px;
      width: 100%;

      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }

      .ant-select-selector {
        height: 100%;
        border: 2px solid #A0A7B7;
      }

      .ant-select-arrow {
        color: #21416F;
      }
    }

    .label {
      font-size: 14px;
      line-height: 23px;
      color: #333333;

      span {
        color: #D92612;
      }
    }

    .delete__button {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }
}