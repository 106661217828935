.modal__charts {

  .modal__indicators {
    padding-bottom: 26px;
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 20px;
    color: #7A8295;

    svg {
      margin-left: 4px;
      width: 10px;
      height: 10px;
      color: #21416F;
    }
  }

  .ant-tabs-nav-list {

    .ant-tabs-tab {
      padding: 30px;

      .ant-tabs-tab-btn {
        color: #1E3968;
      }
    }

    .ant-tabs-tab-active {
      color: #000000;
      font-weight: bold;
    }

    .ant-tabs-ink-bar {
      background: #525566;
    }
  }

  .tab__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;

    .header__info {
      display: flex;
    }

    .info {

      &:first-child {
        margin-right: 44px;
      }

      .text {
        margin: 0;
        color: #000000;
        font-size: 12px;
        line-height: 15px;
      }

      .value {
        margin: 0;
        color: #000000;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
      }
    }

    .select__header {
      display: flex;
      align-items: center;

      .text {
        margin: 0 10px 0 0;
        font-size: 14px;
        line-height: 18px;
      }

      .ant-select, .ant-picker {
        border: 2px solid #A0A7B7;
        border-radius: 4px;
        color: #134176;
      }

      .ant-picker {
        margin-right: 10px;
      }

      .ant-select-suffix {
        color: #21416F;
      }
    }
  }

  .loader__block {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .modal__footer {
    margin-top: 20px;

    h4 {
      margin-bottom: 5px;
    }
  }
}