.ant-modal-content .ant-modal-body {
  min-height: auto;
}

.file-manager__table {

  .ant-table-row   {
    cursor: pointer;
  }

  .ant-table-cell {

    p {
      margin: 0;
    }
  }

  .folder__block {
    display: flex;
    align-items: center;

    p {
      margin: 0 0 0 5px;
    }

    &:hover {

    }
  }

  .action__block {
    display: flex;
    align-items: center;

    .anticon-download {
      margin-right: 10px;
      transition: .3s all;

      &:hover {
        transform: scale(1.1);
        color: #237d23;
      }
    }

    .anticon-edit {
      margin-right: 10px;
      transition: .3s all;

      &:hover {
        transform: scale(1.1);
        color: #0c46d2;
      }
    }

    .anticon-delete {
      transition: .3s all;

      &:hover {
        transform: scale(1.1);
        color: #e80d0d;
      }
    }
  }
}