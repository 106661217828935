.organisation-groups-dropdown_outside-wrapper {
  align-items: center;
  border: 2px solid #a0a7b7;
  border-radius: 4px;
  color: #134176;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 23px;
  margin-right: 1rem;
  padding: 11px 12px;
  width: 250px;
}

.organisation-groups-dropdown_popover-content-wrapper {
  display: flex;
  padding: 10px 40px;

  .organisation-groups-dropdown_popover-content {
    .organisation-groups-dropdown_popover-title {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 20px;
    }

    .organisation-groups-dropdown_popover-checkboxes {
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: auto;

        .ant-checkbox-wrapper {
          border-bottom: 1px solid #D8D8D8;
          display: flex;
          margin-bottom: 18px;
          margin-left: 0 !important;
          padding-bottom: 18px;

          > span:not(.ant-checkbox) {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
  
          span {
            font-weight: 500;
            line-height: 18px;
  
            svg {
              color: #1E3968;
            }
          }
        }
      }
    }

    .organisation-groups-dropdown_popover-radios {
      .ant-radio-group {
        max-height: 500px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
  
        .ant-radio-wrapper {
          display: flex;
          padding-bottom: 18px;
          margin-bottom: 18px;
          border-bottom: 1px solid #D8D8D8;
  
          > span:not(.ant-radio) {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
  
          span {
            font-weight: 500;
            line-height: 18px;
  
            svg {
              color: #1E3968;
            }
          }
        }
  
        .anticon-edit {
          margin-left: 36px;
        }
  
        .anticon-delete {
          margin-left: 16px;
        }
      }
    } 
  }

  .organisation-groups-dropdown_popover-button {
    background: #21416F;
    border: 1px solid #21416F;
    border-radius: 24px;
    color: #FFF;
    display: flex;
    font-size: 14px;
    line-height: 18px;
    margin: 25px auto 0;
    outline: none;
    padding: 10px 30px;
    transition: .3s all;
  
    &:hover {
      opacity: .5;
    }
  }
}