//
// Google font - Nunito
//

@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700,900");

//
// Premium font - Cerebri Sans
//

@font-face {
    font-family: "Cerebri Sans,sans-serif";
    src: url("../fonts/cerebrisans-light.eot");
    src: local("Cerebri-sans Light"), url("../fonts/cerebrisans-light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Cerebri Sans,sans-serif";
    src: url("../fonts/cerebrisans-regular.eot");
    src: local("Cerebri-sans Regular"), url("../fonts/cerebrisans-regular.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "Cerebri Sans,sans-serif";
    src: url("../fonts/cerebrisans-medium.eot");
    src: local("Cerebri-sans Medium"), url("../fonts/cerebrisans-medium.woff") format("woff");
    font-weight: 500;
}
@font-face {
    font-family: "Cerebri Sans,sans-serif";
    src: url("../fonts/cerebrisans-semibold.eot");
    src: local("Cerebri-sans Semibold"), url("../fonts/cerebrisans-semibold.woff") format("woff");
    font-weight: 600;
}
@font-face {
    font-family: "Cerebri Sans,sans-serif";
    src: url("../fonts/cerebrisans-bold.eot");
    src: local("Cerebri-sans Bold"), url("../fonts/cerebrisans-bold.woff") format("woff");
    font-weight: 700;
}

//
// IBM Plex Sans
//

@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
        url("../fonts/Ibmplexsansmedium.woff2") format("woff2"), url("../fonts/Ibmplexsansmedium.woff") format("woff"),
        url("../fonts/Ibmplexsansmedium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"),
        url("../fonts/Ibmplexsansthinitalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsansthinitalic.woff") format("woff"),
        url("../fonts/Ibmplexsansthinitalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/Ibmplexsanstext.woff2") format("woff2"),
        url("../fonts/Ibmplexsanstext.woff") format("woff"), url("../fonts/Ibmplexsanstext.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/Ibmplexsansbold.woff2") format("woff2"),
        url("../fonts/Ibmplexsansbold.woff") format("woff"), url("../fonts/Ibmplexsansbold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"),
        url("../fonts/Ibmplexsansmediumitalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsansmediumitalic.woff") format("woff"),
        url("../fonts/Ibmplexsansmediumitalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"),
        url("../fonts/Ibmplexsansextralightitalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsansextralightitalic.woff") format("woff"),
        url("../fonts/Ibmplexsansextralightitalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
        url("../fonts/Ibmplexsanssemibold.woff2") format("woff2"),
        url("../fonts/Ibmplexsanssemibold.woff") format("woff"),
        url("../fonts/Ibmplexsanssemibold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/Ibmplexsans.woff2") format("woff2"),
        url("../fonts/Ibmplexsans.woff") format("woff"), url("../fonts/Ibmplexsans.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"),
        url("../fonts/Ibmplexsansextralight.woff2") format("woff2"),
        url("../fonts/Ibmplexsansextralight.woff") format("woff"),
        url("../fonts/Ibmplexsansextralight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
        url("../fonts/Ibmplexsanslightitalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsanslightitalic.woff") format("woff"),
        url("../fonts/Ibmplexsanslightitalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
        url("../fonts/Ibmplexsansitalic.woff2") format("woff2"), url("../fonts/Ibmplexsansitalic.woff") format("woff"),
        url("../fonts/Ibmplexsansitalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
        url("../fonts/Ibmplexsansbolditalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsansbolditalic.woff") format("woff"),
        url("../fonts/Ibmplexsansbolditalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"),
        url("../fonts/Ibmplexsanssemibolditalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsanssemibolditalic.woff") format("woff"),
        url("../fonts/Ibmplexsanssemibolditalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/Ibmplexsansthin.woff2") format("woff2"),
        url("../fonts/Ibmplexsansthin.woff") format("woff"), url("../fonts/Ibmplexsansthin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"),
        url("../fonts/Ibmplexsanstextitalic.woff2") format("woff2"),
        url("../fonts/Ibmplexsanstextitalic.woff") format("woff"),
        url("../fonts/Ibmplexsanstextitalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
        url("../fonts/Ibmplexsanslight.woff2") format("woff2"), url("../fonts/Ibmplexsanslight.woff") format("woff"),
        url("../fonts/Ibmplexsanslight.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

// Open Sans

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/opensanslight.woff2") format("woff2"),
        url("../fonts/opensanslight.woff") format("woff"), url("../fonts/opensanslight.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"),
        url("../fonts/opensanslightitalic.woff2") format("woff2"),
        url("../fonts/opensanslightitalic.woff") format("woff"),
        url("../fonts/opensanslightitalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), local("OpenSans"), url("../fonts/opensans.woff2") format("woff2"),
        url("../fonts/opensans.woff") format("woff"), url("../fonts/opensans.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/opensansitalic.woff2") format("woff2"),
        url("../fonts/opensansitalic.woff") format("woff"), url("../fonts/opensansitalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/opensanssemibold.woff2") format("woff2"),
        url("../fonts/opensanssemibold.woff") format("woff"), url("../fonts/opensanssemibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"),
        url("../fonts/opensanssemibolditalic.woff2") format("woff2"),
        url("../fonts/opensanssemibolditalic.woff") format("woff"),
        url("../fonts/opensanssemibolditalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/opensansbold.woff2") format("woff2"),
        url("../fonts/opensansbold.woff") format("woff"), url("../fonts/opensansbold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
        url("../fonts/opensansbolditalic.woff2") format("woff2"), url("../fonts/opensansbolditalic.woff") format("woff"),
        url("../fonts/opensansbolditalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"),
        url("../fonts/opensansextrabold.woff2") format("woff2"), url("../fonts/opensansextrabold.woff") format("woff"),
        url("../fonts/opensansextrabold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"),
        url("../fonts/opensansextrabolditalic.woff2") format("woff2"),
        url("../fonts/opensansextrabolditalic.woff") format("woff"),
        url("../fonts/opensansextrabolditalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'), url('../fonts/Ibmplexserifmedium.woff2') format('woff2'), url('../fonts/Ibmplexserifmedium.woff') format('woff'), url('../fonts/Ibmplexserifmedium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Thin Italic'), local('IBMPlexSerif-ThinItalic'), url('../fonts/Ibmplexserifthinitalic.woff2') format('woff2'), url('../fonts/Ibmplexserifthinitalic.woff') format('woff'), url('../fonts/Ibmplexserifthinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Text'), local('IBMPlexSerif-Text'), url('../fonts/Ibmplexseriftext.woff2') format('woff2'), url('../fonts/Ibmplexseriftext.woff') format('woff'), url('../fonts/Ibmplexseriftext.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'), url('../fonts/Ibmplexserifbold.woff2') format('woff2'), url('../fonts/Ibmplexserifbold.woff') format('woff'), url('../fonts/Ibmplexserifbold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Medium Italic'), local('IBMPlexSerif-MediumItalic'), url('../fonts/Ibmplexserifmediumitalic.woff2') format('woff2'), url('../fonts/Ibmplexserifmediumitalic.woff') format('woff'), url('../fonts/Ibmplexserifmediumitalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif ExtraLight Italic'), local('IBMPlexSerif-ExtraLightItalic'), url('../fonts/Ibmplexserifextralightitalic.woff2') format('woff2'), url('../fonts/Ibmplexserifextralightitalic.woff') format('woff'), url('../fonts/Ibmplexserifextralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'), url('../fonts/Ibmplexserifsemibold.woff2') format('woff2'), url('../fonts/Ibmplexserifsemibold.woff') format('woff'), url('../fonts/Ibmplexserifsemibold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif'), local('IBMPlexSerif'), url('../fonts/Ibmplexserif.woff2') format('woff2'), url('../fonts/Ibmplexserif.woff') format('woff'), url('../fonts/Ibmplexserif.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif ExtraLight'), local('IBMPlexSerif-ExtraLight'), url('../fonts/Ibmplexserifextralight.woff2') format('woff2'), url('../fonts/Ibmplexserifextralight.woff') format('woff'), url('../fonts/Ibmplexserifextralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Light Italic'), local('IBMPlexSerif-LightItalic'), url('../fonts/Ibmplexseriflightitalic.woff2') format('woff2'), url('../fonts/Ibmplexseriflightitalic.woff') format('woff'), url('../fonts/Ibmplexseriflightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Italic'), local('IBMPlexSerif-Italic'), url('../fonts/Ibmplexserifitalic.woff2') format('woff2'), url('../fonts/Ibmplexserifitalic.woff') format('woff'), url('../fonts/Ibmplexserifitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Bold Italic'), local('IBMPlexSerif-BoldItalic'), url('../fonts/Ibmplexserifbolditalic.woff2') format('woff2'), url('../fonts/Ibmplexserifbolditalic.woff') format('woff'), url('../fonts/Ibmplexserifbolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif SemiBold Italic'), local('IBMPlexSerif-SemiBoldItalic'), url('../fonts/Ibmplexserifsemibolditalic.woff2') format('woff2'), url('../fonts/Ibmplexserifsemibolditalic.woff') format('woff'), url('../fonts/Ibmplexserifsemibolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Thin'), local('IBMPlexSerif-Thin'), url('../fonts/Ibmplexserifthin.woff2') format('woff2'), url('../fonts/Ibmplexserifthin.woff') format('woff'), url('../fonts/Ibmplexserifthin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Text Italic'), local('IBMPlexSerif-TextItalic'), url('../fonts/Ibmplexseriftextitalic.woff2') format('woff2'), url('../fonts/Ibmplexseriftextitalic.woff') format('woff'), url('../fonts/Ibmplexseriftextitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif Light'), local('IBMPlexSerif-Light'), url('../fonts/Ibmplexseriflight.woff2') format('woff2'), url('../fonts/Ibmplexseriflight.woff') format('woff'), url('../fonts/Ibmplexseriflight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
