.ant-modal-content .ant-modal-body {
  min-height: auto;
}

.file-manager__table .ant-table-row {
  cursor: pointer;
}
.file-manager__table .ant-table-cell p {
  margin: 0;
}
.file-manager__table .folder__block {
  display: flex;
  align-items: center;
}
.file-manager__table .folder__block p {
  margin: 0 0 0 5px;
}
.file-manager__table .action__block {
  display: flex;
  align-items: center;
}
.file-manager__table .action__block .anticon-download {
  margin-right: 10px;
  transition: 0.3s all;
}
.file-manager__table .action__block .anticon-download:hover {
  transform: scale(1.1);
  color: #237d23;
}
.file-manager__table .action__block .anticon-edit {
  margin-right: 10px;
  transition: 0.3s all;
}
.file-manager__table .action__block .anticon-edit:hover {
  transform: scale(1.1);
  color: #0c46d2;
}
.file-manager__table .action__block .anticon-delete {
  transition: 0.3s all;
}
.file-manager__table .action__block .anticon-delete:hover {
  transform: scale(1.1);
  color: #e80d0d;
}