.rules__page {
  padding: 30px 0 20px;
}
.rules__page .rules__header {
  margin-bottom: 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
}
.rules__page .rules__header:hover {
  opacity: 0.5;
}
.rules__page .rules__header p {
  margin: 0 0 0 5px;
  font-size: 20px;
  line-height: 28px;
}
.rules__page .title {
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  color: #333333;
  font-weight: bold;
  text-transform: uppercase;
}