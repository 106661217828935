.titleBlock {
  background: #134176;
  border-radius: 5px;
  height: 100%;
  padding: 19px;
  width: 100%;

  .content {
    color: white;
    font-size: 14px;
    line-height: 18px;

    p {
      font-size: 24px;
      line-height: 32px;
    }
  }
}